import { useEffect } from 'react';
import ReactDOM, { createPortal } from 'react-dom';

export default function ModalPortal(props) {
	let elementRoot = document.querySelector(props.elementRoot);

	if (!elementRoot) {
		elementRoot = document.createElement('div');
		elementRoot.setAttribute('role', 'dialog');
		if (props.elementRoot) {
			if (props.elementRoot.startsWith('.')) elementRoot.className = props.elementRoot.replace('.', '');
			if (props.elementRoot.startsWith('#')) elementRoot.id = props.elementRoot.replace('#', '');
		} else {
			elementRoot.className = 'modal';
		}
	}

	useEffect(() => {
		document.querySelector('body').appendChild(elementRoot);
		return () => document.querySelector('body').removeChild(elementRoot);
	}, []);

	return ReactDOM.createPortal(props.children, elementRoot);
}
