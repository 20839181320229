import hoistNonReactStatic           from 'UTILS/helpers/hoistNonReactStatics';

import React, { forwardRef, useRef } from 'react';
import useInViewport                 from './useInViewport';

const noop = () => {};

const isFunctionalComponent = (Component) => typeof Component === 'function' && !(Component.prototype && Component.prototype.render);

const isReactComponent = (Component) => Component.prototype && Component.prototype.isReactComponent;

/**
 * @template P
 * @param {ReactNode} TargetComponent
 * @param {IntersectionObserverInit} options
 * @returns {ComponentType<P & {
      onEnterViewport?: VoidFunction
      onLeaveViewport?: VoidFunction
    }>}
 */
function handleViewport(TargetComponent, options, config = { disconnectOnLeave: false }) {
  const ForwardedRefComponent = forwardRef((props, ref) => {
    const refProps = {
      forwardedRef: ref,
      // pass both ref/forwardedRef for class component for backward compatibility
      ...(isReactComponent(TargetComponent) && !isFunctionalComponent(TargetComponent)
        ? {
          ref,
				  }
        : {}),
    };
    return <TargetComponent { ...props } { ...refProps } />;
  });

  const InViewport = ({ onEnterViewport = noop, onLeaveViewport = noop, ...restProps }) => {
    const node = useRef();
    const { inViewport, enterCount, leaveCount } = useInViewport(node, options, config, {
      onEnterViewport,
      onLeaveViewport,
    });

    return <ForwardedRefComponent { ...restProps } inViewport={ inViewport } enterCount={ enterCount } leaveCount={ leaveCount } ref={ node } />;
  };

  const name = TargetComponent.displayName || TargetComponent.name || 'Component';
  InViewport.displayName = `handleViewport(${name})`;

  return hoistNonReactStatic(InViewport, ForwardedRefComponent);
}

export default handleViewport;
