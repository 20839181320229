import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MaterialIcon from 'GLOBAL/MaterialIcon';
import { QuestionnaireContext } from './context';
import Emitter from 'UTILS/Emitter';

const Footer = (props) => {
	const { questions, ...irContext } = useContext(QuestionnaireContext);
	const currentQuestion = questions[irContext.activeIndex];
	// minimum_number_of_questions_answered

	const [, updateState] = useState();
	const forceUpdate = React.useCallback(() => updateState({}), []);
	const [startOverDisabled, setStartOverDisabled] = useState(true);

	const disableBack = () => Boolean(irContext.activeIndex > 0);
	const disableNext = () => {
    let condition = true;

    if(currentQuestion.type === 'multiple_choice'){
      condition = (Object.keys(irContext._val.current).includes(String(currentQuestion.id))
        && irContext._val.current[currentQuestion.id].length >= currentQuestion.minimum_selected
        && (currentQuestion.maximum_selected === null
          || (currentQuestion.maximum_selected !== null
            && irContext._val.current[currentQuestion.id].length <= currentQuestion.maximum_selected)));
    }

    return Boolean((irContext.activeIndex < questions.length - 1) && condition);
  };

	useEffect(() => {
		 let hasChoices = false;

      if (irContext._val.current) {
          hasChoices = Boolean(Object.values(irContext._val.current)
							  .filter(key => Array.isArray(key) && key.length)
							  .flat().length > 0);

          if (irContext.activeIndex >= 0 && hasChoices) {
              setStartOverDisabled(false);
          }
          if (!hasChoices) {
              setStartOverDisabled(true);
          }
      }

	}, [irContext]);


	useEffect(() => {
    const contextUpdate = () => {
      forceUpdate();
    };

    Emitter.addListener('choicesUpdated', contextUpdate);
    Emitter.addListener('questionnaireCleared', contextUpdate);
    return () => {
      Emitter.removeListener('choicesUpdated', contextUpdate);
      Emitter.removeListener('questionnaireCleared', contextUpdate);
    }
  }, []);

	return (
		<div className="irjs__footer">
			<div className={clsx('irjs__footer--actions')}>
    		<button 
    			type="button"
    			className="irjs__btn irjs__btn--sm irjs__btn--startover"
    			aria-label="Back to Start"
    			title="Back to Start"
    			onClick = {(e) => {
                    e.preventDefault();
                    irContext.session.updateSessionData('posted_query', false);
                    irContext.setQuestions([...irContext.questionnaire.questions].sort((a, b) => a.number - b.number));
                    irContext.setActiveIndex(0);
                    irContext.clearQuestionValues();
                    setTimeout(() => {
                        const clearValuesEvent = new CustomEvent('contextSelectionsCleared', { detail: true });
                        document.dispatchEvent(clearValuesEvent);
                    });

                    irContext.analytics.track('guided rec questionnaire start over', {
                        questionnaire_id: Number(irContext.questionnaire.id),
                        start_over_from: irContext.activeIndex,
                        start_over_question_id: irContext.questionnaire.questions[irContext.activeIndex].id,
                    });

                }}
                disabled={ startOverDisabled }
            >
                <MaterialIcon icon="undo" /> 
          </button> 

					<div className="irjs__navigation">
						<button
							type="button"
							className={ clsx(
									'irjs__btn',
									'irjs__btn-footer', 
									'irjs__btn-footer--prev',
									irContext.context.getCustomStyling('basicButton')
							) }
							onClick={ () => {
								irContext.setActiveIndex(irContext.activeIndex !== 0 ? irContext.activeIndex - 1 : 0);
								irContext.analytics.track('guided rec questionnaire back question', {
									from_question: irContext.activeIndex,
									to_question: (irContext.activeIndex !== 0 ? irContext.activeIndex - 1 : 0),
								});
							} }
							disabled={ !disableBack() }
						>
							{irContext.lang.getSlugTranslation({slug:'questionnaire.backbtn'})}
						</button>
						<button
							type="button"
							className={ clsx(
								'irjs__btn',
								'irjs__btn-footer',
			          'irjs__btn-footer--next',
								irContext.activeIndex === questions.length - 1 && 'irjs__btn--solid',
								irContext.context.getCustomStyling('basicButton')
							) }
							onClick={ (e) => {
								e.preventDefault();

								if (irContext.activeIndex < questions.length - 1) {
									irContext.setActiveIndex(irContext.activeIndex < questions.length - 1 ? irContext.activeIndex + 1 : 0);

									irContext.analytics.track('guided rec questionnaire next question', {
										from_question: irContext.activeIndex,
										to_question: irContext.activeIndex < questions.length - 1 ? irContext.activeIndex + 1 : 0,
									});
								}
								if (irContext.activeIndex === questions.length - 1) {
									e.preventDefault();
									if (props.onSubmit && props.onSubmit.constructor === Function) props.onSubmit();
								}
							} }
							disabled={ ( irContext.activeIndex < questions.length - 1 || irContext.activeIndex === 0 ) ? !disableNext() : false }
						>
							{ (irContext.activeIndex < questions.length - 1 || (currentQuestion.reset_choice_selections_on_change && irContext.activeIndex !== questions.length - 1)  || questions.length === 1) ? irContext.lang.getSlugTranslation({slug:'questionnaire.nextbtn'})  : (irContext.context.param?.customText && irContext.context.param?.customText.getRecsBtn) || irContext.lang.getSlugTranslation({slug:'questionnaire.getrecsbtn'}) }
						</button>
					</div>
			</div>

			<div className={clsx('irjs__footer--dots')}>
        {(typeof currentQuestion.dot_navigation_visible === 'boolean' && !currentQuestion.dot_navigation_visible) ? 
          (
            <>
            <span className={ clsx('irjs__footer--dot', 'irjs__footer--dot-active' ) }></span>
            </>
          ) 
          : (<>
            {questions.map((question, idx) => (
              <span
                key={ question.id }
                className={ clsx(
                  'irjs__footer--dot',
                  irContext.activeIndex === idx && 'irjs__footer--dot-active'
                ) }
              ></span>
            ))}
          </>)} 
      </div>
		</div>
	);
};

Footer.propTypes = {};

export default Footer;
