import * as React        from 'react';
import PropTypes         from 'prop-types';
import Link              from 'GLOBAL/Link';
import clsx from 'clsx';

const CardImage = ({
	product,
	className,
	onClick=()=>{},
}) => {
	const {
		url,
		name,
		image_url,
	} = product;
	return (
		<div className={ clsx('irjs__card--image', className) }>
			{(url && url.length) && (
				<Link 
					href={ url } 
					title={ name } 
					onClick={ onClick }
				>
					<img src={ image_url || 'https://s3.amazonaws.com/winering-production/08cf8dd461082263c0abbc2d7e62b622' } alt={ name } />
				</Link>
			)}
			{(!url) && (
				<img src={ image_url || 'https://s3.amazonaws.com/winering-production/08cf8dd461082263c0abbc2d7e62b622' } alt={ name } />
			)}
		</div>
	);
};

CardImage.propTypes = {
	product: PropTypes.shape(),
	className: PropTypes.string,
};

export default CardImage;
