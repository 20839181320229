import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import MaterialIcon from 'GLOBAL/MaterialIcon';

import AppConfig from 'UTILS/AppConfig';

const Header = (props) => {

    return (
        <div className="irjs__form--heading">
			<h1> {(AppConfig.param?.customText && AppConfig.param?.customText.questionnaireHeading) || LokaliseLanguage.getSlugTranslation({slug:'questionnaire.heading'})} </h1> 
			<button 
				type="button"
				className = {clsx('irjs__btn', 'irjs__btn--close')}
				title="Close"
				onClick={(e) => {
                        PreferabliAnalytics.track('guidedrec modal closed', { });
                        if (props.onClose && props.onClose.constructor === Function) props.onClose(e);
                    }
                }>
                <MaterialIcon icon="close" />
            </button>
        </div>
    );
};

export default React.memo(Header);