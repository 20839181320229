import React, { useCallback, useLayoutEffect, useEffect, useContext, useState, createRef, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import debounce from 'lodash.debounce';

import {
  autoUpdate,
  size,
  flip,
  offset,
  shift,
  useId,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  FloatingFocusManager,
  FloatingPortal
} from "@floating-ui/react";

import useMountedRef from 'HOOKS/useMountedRef';
import { ResultsContext } from './context';

const SortByFilter = React.forwardRef(function SortByFilter({
  onSelect=null,
  defaultValue='',
  ...props
}, ref) {

	const _c = useContext(ResultsContext);
	const mounted = useMountedRef();

	const [open, setOpen] = useState(false);
	const [selectedOption, setOption] = useState(false);
	const [activeIndex, setActiveIndex] = useState(null);

	const listRef = useRef([]);
	const { refs, floatingStyles, context, update } = useFloating({
	  whileElementsMounted: autoUpdate,
	  open,
	  onOpenChange: setOpen,
	  middleware: [
	     offset(1), flip(),
	  ],
	  placement: 'bottom-start',
	});
	const role = useRole(context, { role: "listbox" });
	const dismiss = useDismiss(context);
	const listNav = useListNavigation(context, {
	  listRef,
	  activeIndex,
	  virtual: true,
	  loop: true
	});
	const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([role, dismiss, listNav]);

	const options = [
		{
			id: 'recommendation',
			text: 'Recommendation'
		},
		{
			id: 'price_asc',
			text: 'Price ASC'
		},
		{
			id: 'price_desc',
			text: 'Price DESC'
		},
	];

	useEffect(() => {
		if(defaultValue){
			setOption(options.find((_o) => (_o.id === defaultValue)));
			setActiveIndex(options.findIndex((_o) => (_o.id === defaultValue)));
		}
	}, [defaultValue])

	console.log('floatingStyles', floatingStyles, refs);

	return (
    <div 
	    className={ clsx(
	      'irjs__select',
	      props.className
	    ) }
	    aria-expanded={ open }
	    ref={ ref }
    >
    <span className={clsx('irjs__select--legend')}>Sort By:</span>
	<button
		ref={ refs.setReference } 
		{...getReferenceProps({
			type:'button',
			onClick(e) {
				e.preventDefault();
				e.stopPropagation();
				setOpen((pv) => (!pv));
			}
		})}
		aria-label={`Sort By`}
	>
		<span>{ selectedOption.text || 'Sort By' }</span>
	</button>
  	<FloatingFocusManager
        context={context}
        initialFocus={-1}
        modal={false}
        visuallyHiddenDismiss
      >
      <div 
       {...getFloatingProps({
           ref: refs.setFloating,
           style: {
	        ...floatingStyles,
	        overflowY: "auto",
	        zIndex: 99,
	        visibility: open && floatingStyles.transform != null ? 'visible' : 'hidden',
	        pointerEvents: open ? 'auto' : 'none',
	        transition: 'opacity 0.2s ease, transform 0.2s ease',
	        opacity: open ? 1 : 0,
	         
	      },
           className: clsx('irjs__select--list')
         })}
       >
        <ul role={`list`}>
        	{options.map((opt, index) => {
        	  return (
        	    <li
        	    {...getItemProps({
        	      key: index,
        	      ref(node) {
        	        listRef.current[index] = node;
        	      },
        	      onClick() {
        	        setOpen(false);
        	        setOption(opt);
        	        setActiveIndex(index);
        	        if(onSelect && onSelect.constructor === Function) onSelect(opt);
        	      },
        	      role: 'listitem',
        	      className: clsx('irjs__select--item'),
        	    })}
        	    aria-selected={ (activeIndex === index) }
        	    aria-label={ opt.text }
        	    >
        	    { opt.text }
        	    </li>
        	  );
        	})}
        </ul>
       </div>
    </FloatingFocusManager>
    </div>
  );
});

SortByFilter.propTypes = {};

export default React.memo(SortByFilter);
