import React, { useContext, useEffect, useRef, useState, } from 'react';
import PropTypes from 'prop-types';

import IntegrationsApi from 'API/IntegrationsApi';
import { QuestionnaireContext } from 'COMPONENTS/Questionnaire/context';
import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import { fetchUrlParams, formattedRelativePath } from 'UTILS/BrowserUtils';
import Emitter from 'UTILS/Emitter';
import LocalSession from 'UTILS/LocalSession';
import { handleSortedQuestions } from 'UTILS/AppUtils';
import { isFullUrl } from 'HELPERS/validator';

const withQuestionnaire = (WrappedComponent) => {
  return function Component(props) {

    const irContext = useContext(QuestionnaireContext);
    const [isLoading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const isResultPage = () => (isFullUrl(props.context.param?.resultsRedirect) ? window.location.href === props.context.param?.resultsRedirect : window.location.pathname.includes(formattedRelativePath(props.context.param?.resultsRedirect)));

    const submitRec = () => new Promise((resolve, reject) => {
      // run check on questions with required
      const question_requirements = irContext.questions.reduce((obj, question, idx) => {
        if (question.type === 'multiple_choice') {
          if (!obj[question.id]) obj[question.id] = {};
          obj[question.id] = {
            min: question.minimum_selected,
            max: question.maximum_selected,
          };
        }
        return obj;
      }, {});

      let allQuestionsValid = false;

      let invalidQuestions = [];
      if (irContext && irContext._val) {
        const {
          _val,
          choicesWithWords,
          questions,
        } = irContext;

        const _sortedQuestionsIds = Object.keys(_val.current).filter((_qId) => (questions.find((_q) => (Number(_qId) === _q.id))));
        const _queryValues = _sortedQuestionsIds.reduce((obj, _qId) => {
            const _question = questions.find((_q) => (Number(_qId) === _q.id));

            if(_question.type === 'multiple_choice') obj[_qId] = _val.current[_qId];
            if(_question.type === 'price_range' || _question.type === 'ns_slider'){
              obj[_qId] = {};
              if(_val.current[_qId]?.min > _question.default_range_min) obj[_qId]['min'] = _val.current[_qId]?.min;
              if(_val.current[_qId]?.max < _question.default_range_max) obj[_qId]['max'] = _val.current[_qId]?.max;
            }
            return obj;
          }, {});

        const _choices = Object.values(_queryValues).filter((_values) => (Array.isArray(_values)));
        const _questionChoiceIds = Object.keys(_queryValues).filter((_key) => (Array.isArray(_val.current[_key])));
        const _allChoices = _choices.flat();

        allQuestionsValid = _choices.length ? _choices.every((choice_arr, idx) => {
          const question_id = Number(_questionChoiceIds[idx]);
          const question_requirement = question_requirements[question_id];
          const question = questions.find((question) => question.id === question_id);

          if (Array.isArray(choice_arr)) {
            if (choice_arr.length) {
              if ((question_requirement.min >= 0 || !question_requirement.min) &&
                  (question_requirement.max || !question_requirement.max)
              ) {
                if (choice_arr.length >= question_requirement.min && !question_requirement.max) {
                  return true;
                }
                if (
                  choice_arr.length >= question_requirement.min &&
                    question_requirement.max &&
                    choice_arr.length <= question_requirement.max
                ) {
                  return true;
                }
              }
              if (!question_requirement.min && !question_requirement.max) {
                return true;
              }
            } else {
              if (question_requirement 
                && (question_requirement.min === 0 || !question_requirement.min) 
                && (question_requirement.max >= 0 || !question_requirement.max)) {
                return true;
              }
            }

            return false;
          }
          return true;
        }) : false;


        if (allQuestionsValid) {
          let _filters = [];
          const questionIds = [...new Set([...irContext.questions])].map((_q) => (_q.id)).flat();
          const _sortedValues = Object.keys(irContext._val.current)
                                .filter(key => questionIds.includes(Number(key)))
                                .reduce((acc, key) => {
                                    acc[Number(key)] = irContext._val.current[Number(key)];
                                    return acc;
                                }, {});

          const _pQuestion = [...new Set([...irContext.questions])].filter((_q) => (_q.type === 'price_range'));

          if(_pQuestion && _pQuestion.length){

            const { min, max } = irContext._val.current[_pQuestion[0].id];

            if(min > _pQuestion[0].default_range_min){
              if(min > max){
                _filters.push({key:'price_max', value: min});
              } else {
                _filters.push({key:'price_min', value: min});
              }
            }
            if(max < _pQuestion[0].default_range_max){
              if(min > max){
                _filters.push({key:'price_min', value: max});
              } else {
                _filters.push({key:'price_max', value: max});
              }
            }
          }

          const _responses = Object.entries(_sortedValues).reduce((arr, entry) => {
            let [_qId, _qValues] = entry;
            const _tempQ = irContext.questionnaire.questions.find((_q) => (_q.id === Number(_qId)));

            let _pO = {
              question_id: Number(_qId)
            };

            if(_tempQ){
              if(_tempQ.type === 'multiple_choice'){
                _pO.choice_ids = _qValues.flat();
              }
              if(_tempQ.type === 'ns_slider' || _tempQ.type === 'price_range'){
                _pO = {..._pO, ..._qValues};
              }
            }

            arr.push(_pO)

            return arr;
          }, []);

          // NS Questions pass as own values
          props.session.createSessionItems({
            questionnaire_values: _queryValues,
            question_responses: _responses,
            filters: _filters,
            questionnaire_id: irContext.questionnaire.id,
            integration_id: props.context.integration_id,
            posted_query: false,
          });

          resolve();

        } else {
          reject();
        }

      }
    });

    const handleValueChange = async (questionId, values) => {
      const { _val } = irContext;
      
      const _tmpValues = Object.assign({}, { ..._val.current });
      const isNonSignal = Boolean((irContext.nsQuestionIds.length && irContext.nsQuestionIds.includes(questionId)));
      const _question = irContext.questionnaire.questions.find((question) => question.id === questionId);

      if (Array.isArray(values) && _question.type === 'multiple_choice') {
        const questionValues = values;
        if (!_tmpValues[questionId]) _tmpValues[questionId] = [];
        _tmpValues[questionId] = questionValues;
        const _question = irContext.questionnaire.questions.find((question) => question.id === questionId);

        const nonCurrentQuestions = irContext.questionnaire.questions.filter(
          (question) => question.type === 'multiple_choice' && question.id !== questionId && question.number > _question.number
        );

        if (questionValues.length) {
          const updateQuestionSelections = nonCurrentQuestions.reduce((obj, question, idx) => {
            if (!obj[question.id] && _tmpValues[question.id] && _tmpValues[question.id].length) {
              // obj[question.id] = []; //
              question.choices.forEach((questionChoice) => {
                const {
                  id,
                  requires_choice_ids,
                } = questionChoice;
                if (_val.current[question.id].includes(id) 
                  && (!requires_choice_ids.length || 
                    (requires_choice_ids.length && requires_choice_ids.some((val) => questionValues.includes(Number(val))))
                  )) {
                  if(!obj[question.id]) obj[question.id] = [];
                  obj[question.id].push(questionChoice.id);
                }
              });
            }
            return obj;
          }, {});

          Object.entries(updateQuestionSelections).forEach(([questionID, questionSelection]) => {
            _tmpValues[questionID] = questionSelection;
          });
        } else if (!questionValues.length) {
          _tmpValues[_question.id] = questionValues;
        }

        if(_question.reset_choice_selections_on_change) {
          irContext.setQuestionnaireValues(_tmpValues);
          await irContext.clearQuestionValues(_question);
        }

      }

      if (values && typeof values === 'object' && (_question.type === 'price_range' || _question.type === 'ns_slider')) {
        const _min = _question.default_range_min || irContext.questionnaire.default_price_min || 0;
        const _max = _question.default_range_max || irContext.questionnaire.default_price_max || 500;

        if(Object.keys(values).length){ 
          _tmpValues[_question.id] = Object.keys(values).reduce((obj, key) => {
            if(!Object.keys(obj).includes(key)) obj[key] = null;
            let _v = values[key];
            if(key === 'min') _v = (Number(_v) >= 0 && Number(_v) > _min) ? _v : _min;
            if(key === 'max') _v = ((Number(_v) < _max) || (Number(_v) > 0)) ? _v : _max;
            obj[key] = _v;
            return obj;
          }, _tmpValues[_question.id]);

        }
      }

      if(!_question.reset_choice_selections_on_change) irContext.setQuestionnaireValues(_tmpValues);

      const updateQuestions = async () => new Promise((resolve, reject) => {

        const _tempQuestions = [...new Set([...irContext.questionnaire.questions])];
        const _hasQuestionRequired = _tempQuestions.some((_question) => (_question.requires_choice_ids.length));

        if (_hasQuestionRequired) {
            const hasRequiredChoices = (question) => {
                const {
                    requires_choice_ids,
                    id
                } = question;
                if (Object.values(irContext._val.current).length) {
                    const allChoices = Object.values(irContext._val.current)
                        .filter(key => Array.isArray(key) && key.length)
                        .flat();

                    return ((allChoices.length && requires_choice_ids.length && requires_choice_ids.every((val) => allChoices.includes(Number(val)))) || false);
                }
                return false;
            };

            const _sortedQuestions = _tempQuestions.reduce((arr, _question) => {
                if (!_question.requires_choice_ids.length || (_question.requires_choice_ids.length && hasRequiredChoices(_question))) arr.push(_question);
                return arr;
            }, []);

            //non signal questions
            const _qs = irContext.questionnaire.questions.filter((_q) => (!_q.requires_choice_ids.length));

            // update next questionnaire values for keep existing and sort ASC ordering
            irContext.setQuestions((_pQ) => ([...new Set([..._qs, ..._sortedQuestions])].sort((a, b) => a.number - b.number)));
            resolve(([...new Set([..._qs, ..._sortedQuestions])].sort((a, b) => a.number - b.number)));
        }

        if(!_hasQuestionRequired) resolve([...irContext.questions]);
      });

      await updateQuestions().then((_questions) => {

        if (_question.type === 'multiple_choice' &&
            (!_question.minimum_selected || _question.minimum_selected === 1) &&
            _question.maximum_selected === 1) {

            const _choiceId = values[0];
            const _choice = _question.choices.find((_c) => (_c.id === _choiceId));

            if (_choice) {

                const _cInput = document.querySelector(`input[id=choice-${_question.id}-${_choice.id}-${_choice.number}] + label`);
                if(_cInput){
                  _cInput.addEventListener('animationend', () => {
                      setTimeout(() => {
                           if(irContext.activeIndex !== (_questions.length - 1)) {
                            irContext.setActiveIndex( irContext.activeIndex + 1 );
                            props.analytics.track('guided rec automatic next question', {
                                onlyMp: true,
                                questionnaire_id: irContext.questionnaire.id,
                                question_id: _question.id,
                                question_number: _question.number
                            });
                          }

                          if(irContext.activeIndex === (_questions.length - 1)) {
                            Emitter.emit('preferabli.submitRec');
                          }
                      }, 125);
                  });
                }
            }
        }

      });

    };

    useEffect(() => {
      const getQuestionnaire = async (params) => {
        try {

          setLoading(true);

          const resQuestionnaire = await props.api.getQuestionnaire({
            id: params && params.questionnaire_id || props.context.param?.questionnaire_id || irContext.query.questionnaire_id,
          });
          if (resQuestionnaire) {

            await irContext.setChoicesFilters(resQuestionnaire.questions).then((_localData) => {
              const _questions = handleSortedQuestions({ context: {...irContext, _val: {current: _localData} }, questions: resQuestionnaire.questions});

              irContext.setQuestions(_questions);
              irContext.setNSQuestionIds(resQuestionnaire.questions.filter((_q) => (_q.mixpanel_group_slug.startsWith('ns_'))));
              irContext.setQuestionnaire(resQuestionnaire);

              if(Object.keys(params).length){
                irContext.setQuery({
                questionnaire_id: params.questionnaire_id || irContext.query.questionnaire_id,
                integration_id: params.integration_id || irContext.query.integration_id,
                });

                props.session.createSessionItems({
                questionnaire_id: params.questionnaire_id || irContext.query.questionnaire_id,
                integration_id: params.integration_id || irContext.query.integration_id,
                });
              }

              props.analytics.track('guided rec questionnaire loaded', {  
                questionnaire_id: Number(params.questionnaire_id),
              });

              props.context.setQuestionnaire(resQuestionnaire);
              setLoading(false);
            });
          }
        } catch (e) {

          if(window.Rollbar) window.Rollbar.warning('Fetching Questionnaire Errored', { questionnaire_id: params && params.questionnaire_id || props.context.param?.questionnaire_id || irContext.query.questionnaire_id })

          props.analytics.track('guided rec questionnaire errored', {
              error: 'api',
              questionnaire_id: params && params.questionnaire_id || props.context.param?.questionnaire_id || irContext.query.questionnaire_id,
          });

          setHasError(true);
        }
      };

      const urlParams = fetchUrlParams();
      getQuestionnaire(urlParams);

      return () => {};
    }, []);

    const questionnaireProps = {
      isLoading,
      hasError,
      handleValueChange,
      submitRec,
      isResultPage,
      irContext,
    };

    return <WrappedComponent {
      ...props
    } {
      ...questionnaireProps
    }
    />;


  };
};

export default withQuestionnaire;
