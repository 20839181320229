import React from 'react';

const withContext = (ContextProvider, Component) => (props) => {
  return (
    <ContextProvider parameters={{ ...props }}>
      <Component { ...props } />
    </ContextProvider>
  );
};
export default withContext;
