export const fetchUrlParams = (string = null) => {
  const searchParamsString = string || window.location.search;
  if (searchParamsString.length) {
    const params = new URLSearchParams(searchParamsString);

    const obj = {};

    // iterate over all keys
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key);
      } else {
        const value = params.get(key);
        if (value.match(/(\[)[^*]+(\])/)) {
          const cleanedVal = value.replace(/[\[\]']+/g, '').split(',');
          obj[key] = cleanedVal;
        } else {
          obj[key] = params.get(key);
        }
      }
    }

    return obj;
  }
  return {};
};

export const serializeParams = (params) => Object.keys(params)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
  .join('&');

export const createUTMTag = (params) => {

  const {
    url,
    ...utmKeys
  } = params;

  if(!url || url === null || url === undefined) return '';

  return `${url}${url.indexOf('?') > 0 ? '&' : '?'}${Object.keys(utmKeys).map((key) => `utm_${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&')}`;
}

export const formattedRelativePath = (string) => {
  const relative_path = string.toLowerCase().trim();
  return !relative_path.startsWith('/') ? `/${relative_path}` : relative_path;
};