import { convertCssUnit, stringHasUnit }           from 'HELPERS/formatter';

class AppConfig {
	constructor() {
		this.integration_id = null;
		this.questionnaire_id = null;
		this.defaultTimeout = null;
		this._appPrefix = 'preferabliGuidedRec';
		this.param = null;
		this.lang = null;
		this.questionnaire = null;
	}

	getQuestionnaireId = () => this.questionnaire_id;

	getIntegrationId = () => this.integration_id;

	getDefaultTimeout = () => this.defaultTimeout;

	getLanguage = () => this.lang;

	setQuestionnaireId = (questionnaire_id) => {
		this.questionnaire_id = questionnaire_id;
	};

	setIntegrationId = (integration_id) => {
		this.integration_id = integration_id;
	};

	setDefaultTimeout = (timeoutDur) => {
		this.defaultTimeout = timeoutDur;
	};

	setParameters = (options) => {
		this.param = options;
	}

	getParameter = (prop) => {
		return this.param[prop] || null;
	}

	setLanguage = (lang) => {
		this.lang = lang;
	}

	setQuestionnaire = (questionnaire) => {
		this.questionnaire = questionnaire;
	}

	fetchCustomClass = (className) => {
		const styles = className.indexOf('.') >= 0 ? className.split('.') : className;
		if (this.param.customStyling && Object.keys(this.param.customStyling).length) {
			const {
				questionnaire, results, sidemenu, ...universal
			} = this.param.customStyling;
			if (Array.isArray(styles) && styles.length) {
				if (
					styles[0] === 'questionnaire'
					&& questionnaire
					&& Object.keys(questionnaire).length
					&& Object.keys(questionnaire).includes(styles[1])
				) {
					return questionnaire[styles[1]].length ? questionnaire[styles[1]] : '';
				}

				if (styles[0] === 'results' && results && Object.keys(results).length && Object.keys(results).includes(styles[1])) {
					return results[styles[1]].length ? results[styles[1]] : '';
				}
				if (styles[0] === 'sidemenu' && sidemenu && Object.keys(sidemenu).length && Object.keys(sidemenu).includes(styles[1])) {
					return sidemenu[styles[1]].length ? sidemenu[styles[1]] : '';
				}
			}

			if (typeof styles === 'string' && Object.keys(universal).length && Object.keys(universal).includes(styles)) {
				return universal[styles];
			}
		}
		return '';
	};

	getCustomStyling = (styleParams) => {
		if (Array.isArray(styleParams) && styleParams.length) {
			const classes = styleParams.reduce((obj, styleParam) => {
				const cs_class = this.fetchCustomClass(styleParam);
				if (cs_class.length) {
					obj.push(cs_class);
				}
				return obj;
			}, []);
			return classes.length ? classes[0] : '';
		}

		if (typeof styleParams === 'string') {
			return this.fetchCustomClass(styleParams);
		}
	};

	hasDecimalPlaces = () => {
		if (!this.param.showDecimalPlaces) return 0;
		if (this.param.showDecimalPlaces && this.param.numDecimalPlaces) return Number(this.param.numDecimalPlaces);
		return 2;
	}

	setStickyTop = () => {
		let stickyTop = this.param.sidemenuStickyTop;
		if (typeof stickyTop === 'string' && stringHasUnit(stickyTop)) {
			stickyTop = convertCssUnit(stickyTop);
		}
		if (Object.keys(param).includes('sidemenuStickyTop')) return this.param.sidemenuStickyTop;
		if (
			!Object.keys(this.param).includes('sidemenuStickyTop')
			&& Object.keys(this.param).includes('sidemenuStatic')
			&& !this.param.sidemenuStatic
		) return 50;

		return false;
	}

	checkSidemenuFixed = () => {
		let stickyTop = this.param.sidemenuStickyTop;
		if (typeof stickyTop === 'string' && stringHasUnit(stickyTop)) {
			stickyTop = convertCssUnit(stickyTop);
		}

		if (Object.keys(this.param).includes('sidemenuStatic')) return this.param.sidemenuStatic;
		if (!Object.keys(this.param).includes('sidemenuStatic') && Object.keys(param).includes('sidemenuStickyTop') && stickyTop >= 0) return true;

		return false;
	}


}

const IsoAppConfig = new AppConfig();
export { IsoAppConfig as default };
