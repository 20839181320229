import React, { useState, useContext, useEffect, useRef, } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import parse, { attributesToProps, domToReact } from 'html-react-parser';

import LoadingDots from 'GLOBAL/LoadingDots';
import handleViewport from 'HOOKS/handleViewport';
import usePrevious from 'HOOKS/usePrevious';
import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import { pick } from 'HELPERS/object';
import { titleize, underscore } from 'HELPERS/text';
import ProductCard from './productcard';
import { ResultsContext } from './context';

const GroupHeading = React.forwardRef(function GroupHeading(props, ref) {

	const _c = useContext(ResultsContext);

	const getCustomTypeHeading = (type) => {
		if (_c.context.param?.customText 
			&& Object.keys(_c.context.param?.customText).includes('categories')
	      && Object.keys(_c.context.param?.customText.categories).length
		) {	
			type = type.toLowerCase();
			const _keys = Object.keys(_c.context.param?.customText.categories);
			if (_c.context.param?.typesPlural) type = type.slice(0, -1); // create singleton
			if(_keys.includes(underscore(type))){
				return `${titleize(type)}${(_c.context.param?.typesPlural) ? 's' : ''}`;
			}
		}
		return false;
	};

	return (
		<div className={ clsx('irjs__results--heading') }>
			<h3>{ getCustomTypeHeading(props.nonLatin) || _c.lang.getSlugTranslation({slug:`type.${underscore(props.nonLatin)}`}) }</h3>
		</div>
	);
});

GroupHeading.propTypes = {
	className: PropTypes.string,
	style: PropTypes.shape(),
};

const ResultGroupHeading = React.memo(GroupHeading);

const CustomResultCard = ({ html, ...propParams }) => {

	const options = {
		replace: ({attribs, children, name, ...domNodeProps}) => {

			if(!domNodeProps.parent && children && children.length){
				const Tag = name;
				const props = attributesToProps(attribs);
				return (<Tag {...props} onClick={ propParams.onClick }>{domToReact(children, options)}</Tag>);
			}
			if (attribs && name === 'input') {
				const props = attributesToProps(attribs);
				return (<input { ...props } onChange={ () => {} } />);
			}
			if (attribs && name === 'select') {
				const props = attributesToProps(attribs);
				return (<select { ...props } onChange={ () => {} } />);
			}
		},
	};
	return parse(html, { trim: true, ...options });
};

const ResultsGrouping = (props) => {

	const _c = useContext(ResultsContext);

	const {
		type, results, hasNextPage, nonLatinType, loadMore, forwardedRef, id,
	} = props;

	const prevResults = usePrevious(results);
	const [isLoadingMore, setLoading] = useState(false);
	const [showLoadMore, setShowLoadMore] = useState(hasNextPage);
	const [cards, setCards] = useState([]);
	const [waitForCallback, setWaitForCallback] = useState(typeof props.renderProductCards === 'function');
	const isCompact = Boolean(
		_c.context.param?.customStyling
			&& Object.keys(_c.context.param?.customStyling).length
			&& Object.keys(_c.context.param?.customStyling).includes('compact')
			&& _c.context.param?.customStyling.compact
	);

	const handleLoadMore = (e) => {
		e.preventDefault();
		setLoading(true);

		let node = e.target;
		if(node.nodeName.toLowerCase() !== 'button') node = e.target.parentNode;
		if(typeof props.renderProductCards === 'function') setWaitForCallback(true);

		if(loadMore && loadMore.constructor === Function){
			loadMore(node.dataset).then(() => {
				if(!props.renderProductCards) setLoading(false);
			}).catch((err) => {
				setLoading(false);
				// alert(`Unable to load more ${type} recommendations. Please try again later.`);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {

		if(results){

			const _doProducts = async () => {
			
				const _hasValidUrl = async (url) => (await fetch(url, {method: 'HEAD'})).ok;

				let newResults = results;
				if (typeof prevResults === 'object' && Object.values(prevResults).length) {
					newResults = newResults.filter((item) => !Object.values(prevResults).includes(item));
				}

				const allLookups = await Object.entries(newResults).reduce(async (acc, result) => {
					const joiner = await acc;
					const [objIdx, { product, lookups }] = result;
					const _valid =  (__ENV__ === 'production') ? await _hasValidUrl(lookups[0].landing_url) : Promise.resolve();
		            if (lookups[0].landing_url && typeof lookups[0].landing_url === 'string' && lookups[0].landing_url.length && _valid) joiner.push(lookups[0]);
					return joiner;
				}, Promise.resolve([]));

				props.renderProductCards(allLookups)
					.then((returnCards) => {

						const _cleanHtmlCards = returnCards.filter((_cardHtml) => (String(_cardHtml).length > 0));

						setCards([
							...new Set([
								...cards,
								..._cleanHtmlCards.map((cardHtml, idx) => {
									if (typeof cardHtml === 'string') {
										return { html: cardHtml, key: allLookups[idx].value, product: allLookups[idx] };
									}
									if (cardHtml.constructor === Promise) {
										/// do nothing
									}
								}),
							]),
						]);
						setWaitForCallback(false);
						if(typeof props.onComplete === 'function') props.onComplete();
						if(isLoadingMore) setLoading(false);
					})
					.catch((error) => {
						if(window.Rollbar) window.Rollbar.warning('Questionnaires - Fetch Cards', { questionnaire_id: _c.session.getSessionItem('questionnaire_id') });
						console.log('error',  error);
					});
		};


		if (props.renderProductCards && props.renderProductCards.constructor === Function && Object.entries(results).length) _doProducts();
		if(!props.renderProductCards && props.onComplete.constructor === Function) props.onComplete();
		if ((results.length === 30 || !hasNextPage || results.length === _c.context.questionnaire.max_results_per_type)) setShowLoadMore(false);
	}	

	}, [results]);

	useEffect(() => {
		if (cards.length && !waitForCallback) {
			if (typeof props.onRenderComplete === 'function') props.onRenderComplete();
		}
	}, [cards, waitForCallback]);


	const RowTag = _c.context.param?.customStyling.results && _c.context.param?.customStyling.results.rowTagEl
		? _c.context.param?.customStyling.results.rowTagEl
		: 'div';

	return (
		<div ref={ forwardedRef } className="irjs__results--section" id={ id }>
			<ResultGroupHeading text={ type } nonLatin={ nonLatinType } type={ type.toLowerCase() } />
			{(typeof props.renderProductCards === 'function' && waitForCallback && cards.length === 0) && (
				<LoadingDots 
					className={ clsx('fade', waitForCallback && 'in') } 
					showText={ false }
					color={ ((_c.context.param?.customStyling && _c.context.param?.customStyling.loadingColor) ? { backgroundColor: _c.context.param?.customStyling.loadingColor } : {}) }
				/>
			)}
			{(results.length > 0 || cards.length > 0) && (
				<>
				<RowTag className={ clsx('irjs__results--row', _c.context.getCustomStyling('results.rowClass')) }>
					{typeof props.renderProductCards === 'function' && cards.map((card, idx) => <CustomResultCard { ...card } onClick={(e) => {
						let capture = pick(card.product, ['id','variant_id','variant_year','product_name','price','format_ml','landing_url']);
						_c.analytics.track('guided rec product clickthru', {
							product_id: card.product.value,
				            ...capture,
				        });
					}} />

					)}

					{!props.renderProductCards
						&& results.map((result) => {
							const { lookups, product } = result;
							return (
								<ProductCard
									compact={ isCompact }
									key={ result.id }
									hasMultipleLookups={ lookups.length > 1 }
									lookups={ lookups }
								/>
							);
						})}
				</RowTag>
				{(showLoadMore && ((!props.renderProductCards && results.length > 0) || (props.renderProductCards && cards.length > 0))) && (
					<button
						type="button"
						className={ clsx('irjs__btn', 'irjs__btn--solid', 'mx-auto', _c.context.getCustomStyling('basicButton')) }
						data-offset={ results.length }
						data-type={ nonLatinType.toLowerCase() }
						onClick={ handleLoadMore }
						disabled={ ((!props.renderProductCards && isLoadingMore) || (props.renderProductCards && waitForCallback)) }
					>
						{((!props.renderProductCards && isLoadingMore) || (props.renderProductCards && waitForCallback)) ? ( <LoadingDots className={ clsx('fade', 'in') } showText={ false } color={ {backgroundColor: '#fff'} } /> ) : ( <span>{ _c.lang.getSlugTranslation({slug:'result.showmorebtn'}) }</span> ) }
					</button>
				)}
				</>
			)}

		</div>
	);
};

ResultsGrouping.propTypes = {
	id: PropTypes.string,
	type: PropTypes.string,
	nonLatinType: PropTypes.string,
	results: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]),
	hasNextPage: PropTypes.bool,
	renderProductCards: PropTypes.func,
	onRenderComplete: PropTypes.func,
	loadMore: PropTypes.func,
	onComplete: PropTypes.func,
	forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	inViewport: PropTypes.bool,
};

const ResultGrouping = handleViewport(ResultsGrouping, { rootMargin: '-200px 0px' });

const ResultsSection = (props) => {
	const loadingState = useRef(true);
	const [updated, force] = useState();

	useEffect(() => {
		loadingState.current = false;
	}, [updated]);

	return (
		<ResultGrouping
			onLeaveViewport={ (entry) => {
				if (loadingState.current) return;
				if (props.onScrollChange && props.onScrollChange.constructor === Function) props.onScrollChange(entry, props.id);
			} }
			onComplete={ () => {
				force();
				props.onComplete();
			} }
			{ ...props }
		/>
	);
};

ResultsSection.propTypes = {
	id: PropTypes.string.isRequired,
	onScrollChange: PropTypes.func,
	activeSection: PropTypes.string,
	onComplete: PropTypes.func,
};

export default ResultsSection;
