import AppConfig from 'UTILS/AppConfig';

class LocalSession {
  constructor() {
    this.storage = {
      cookies: {},
      session: {},
    };

    this.sessionPrefix = null;
  }

  setSessionPrefix = (prefix) => {
    this.sessionPrefix = prefix;
  }

  getSessionPrefix = () => this.sessionPrefix;

  getLocalCookies = () => {
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookies = decodedCookie.split('; ').filter((cookie) => cookie.startsWith(this.sessionPrefix));
    var sessionItems = Object.entries(window.sessionStorage).filter(([key, value]) => key.startsWith(this.sessionPrefix));

    if (sessionItems.length) {
    for (const [key, value] of sessionItems) {
      const isJSON = this.checkValidJson(value);
      this.storage.session[key.toLowerCase().replace(this.sessionPrefix, '')] = isJSON ? JSON.parse(value) : value;
    }
    }

    if (cookies.length) {
    cookies.forEach((cookie) => {
      const [key, value] = cookie.split('=');
      this.storage.cookies[key.toLowerCase()] = value;
    });
    }
  }

  checkValidJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  getSessionItem = (sessionKey) => (typeof this.storage.session[sessionKey.toLowerCase()] === 'undefined') ? null : this.storage.session[sessionKey.toLowerCase()];

  getSessionStorage = () => this.storage.session || null;

  updateSessionData = (sessionKey, value) => {
    window.sessionStorage.setItem(`${this.sessionPrefix}${sessionKey.toLowerCase()}`, JSON.stringify(value));
    this.storage.session[sessionKey.toLowerCase()] = value;
  };

  removeSessionItem = (sessionKey) => {
    window.sessionStorage.removeItem(`${this.sessionPrefix}${sessionKey.toLowerCase()}`);
    delete this.storage.session[sessionKey.toLowerCase()];
  };

  getCookies = () => this.storage.cookies || null;

  getCookieItem = (item) => (typeof this.storage.cookies[item] === 'undefined') ? null : this.storage.cookies[item];

  createSessionItems = (params) => {
    if (Object.keys(params).length) {
      Object.keys(params).map((sessionKey) => {
        // add integration_id to session data
        window.sessionStorage.setItem(`${this.sessionPrefix}${sessionKey.toLowerCase()}`, JSON.stringify(params[sessionKey]));
        this.storage.session[sessionKey.toLowerCase()] = params[sessionKey];
      });
    }
  };

  createCookie = (cookies) => {
    if (Array.isArray(cookies) && cookies.length) {
      cookies.map((cookieParams) => {
        const { name, value, expired } = cookieParams;

        document.cookie = `${name}=${value};expires=${expired};${!__ENV__.local ? `domain=.${window.location.host};` : ''}path=/;`;
        this.storage.cookies[name.toLowerCase()] = value;
      });
    }
  };

  clearSessionData = () => {
    window.sessionStorage.clear();
    this.storage.session = {};
  };

  clear = () => {
    this.storage = {
      cookies: {},
      session: {},
    };
    window.sessionStorage.clear();
  };
}

const LocalSessionSingleton = new LocalSession();

export default LocalSessionSingleton;
