export const mergeResults = (data) => Object.values(data.types).reduce((obj, key) => {
    key.results = key.results.map((result) => {
        result.lookups.map((lookup) => {
            lookup.type = key.type;
            return lookup;
        });
        return result;
    });
    obj = obj.concat(key.results);
    return obj;
}, []);

export const getResultsTotal = (data) => Object.values(data.types).reduce((a, b) => a + b.results.length, 0);


export const handleSortedQuestions = (data) => {

    const {
        context,
        questions,
    } = data;

    let _qs = [...questions].filter((_q) => (!_q.requires_choice_ids.length)); /// filter to questions without required_choice_ids

    if (context._val.current) {
        const _tempQuestions = [...new Set([...questions])];
        const _hasQuestionRequired = _tempQuestions.some((_question) => (_question.requires_choice_ids.length));
        if (_hasQuestionRequired) {
            const hasRequiredChoices = (question) => {
                const { requires_choice_ids, id } = question;

                if (Object.keys(context._val.current).length) {
                    const _filtersToChoices = Object.values(context._val.current).filter((_values) => (Array.isArray(_values)));
                    const allChoices = _filtersToChoices
                        .filter(key => Array.isArray(key) && key.length)
                        .flat();

                    return ((allChoices.length && requires_choice_ids.length && requires_choice_ids.every((val) => allChoices.includes(Number(val)))) || false );
                }

                return false;
            };

            const _sortedQuestions = _tempQuestions.reduce((arr, _question) => {
                if (!_question.requires_choice_ids.length || (_question.requires_choice_ids.length && hasRequiredChoices(_question))) arr.push(_question);
                return arr;
            }, []);

            _qs = _sortedQuestions.sort((a, b) => a.number - b.number);
        }
    }

    return _qs;

}