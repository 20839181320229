export const gaConfig = {
	trackers: {
		6301: 'B3T33887P0', // wine house
		8289: 'VFKEHSYHD0', // wine central nz
		8406: 'EQPGME7X05', // your wine store
		8300: 'C4Z2RGQ7LS', // gordons
		8409: 'DW1PZ5V2F6', // vinoteca.gt
		8412: 'H6M0JW7MJ7', // wine cellarage
		8414: 'NDT2GDCYVJ', // hi-times
		8412: 'Q9BTL5J0WY', // vintage wine merchant
		8410: 'TXSTHYRXN4', // wine legacy
		8299: 'H28X0W27X9', // applejack
		8417: 'F511E4H281', // lukas
		8297: 'S4SX739SW1', // luckysomm
	},
};
