import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import AppConfig from 'UTILS/AppConfig';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import Placeholder from './placeholder';

const NoResultsPlaceholder = (props) => {

	let noResultProps = {
		heading: '',
		copySize: 'h2',
		copy: (
			<>
				{LokaliseLanguage.getSlugTranslation({slug:'result.noresultsheading'})}
				<br />
				{LokaliseLanguage.getSlugTranslation({slug:'result.noresultssubheading'})}
			</>
		),
		instantRecButton: {
			text: LokaliseLanguage.getSlugTranslation({slug:'result.updatebtn'}),
		},
		type: 'noresults',
	};

	if (AppConfig.param?.noResults&& Object.keys(AppConfig.param?.noResults).length) {
		const { text, textSize, ...paramProps } = AppConfig.param?.noResults;

		noResultProps = {
			...noResultProps,
			...paramProps,
		};

		if (text) {
			noResultProps = {
				...noResultProps,
				copy: React.isValidElement(parse(text)) ? (
					<>{parse(text)}</>
				) : (
					<span style={ { fontSize: textSize } }>{text}</span>
				),
			};
		}
	}



	return (
		<Placeholder {...noResultProps} />
	);
};

export default React.memo(NoResultsPlaceholder);
