import React, {
	useContext, useEffect, useRef, useState, createRef
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import parse from 'html-react-parser';
import styled from 'styled-components';

import LoadingDots from 'GLOBAL/LoadingDots';
import PoweredByLogo from 'LOGO/PoweredBy';
import PreferabliLogo from 'LOGO/Preferabli';
import MaterialIcon from 'GLOBAL/MaterialIcon';
import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import LocalSession from 'UTILS/LocalSession';
import { getResultsTotal, mergeResults } from 'UTILS/AppUtils';
import { convertCssUnit, stringHasUnit } from 'HELPERS/formatter';

import ResultsSection from './achoredresults';
import Sidebar from './sidebar';
import Placeholder from './placeholder';
import NoResultsPlaceholder from './NoResultsPlaceholder';
import ErrorPlaceholder from './errorplaceholder';
import DefaultPlaceholder from './defaultplaceholder';
import SortByFilter from './sortbyfilter';
import Conditional from 'GLOBAL/Conditional';


const NotiCloseButon = styled.a`
position:absolute;
top:0.3125rem;
right:0.3125rem;
transform-origin: center center;
transform: scale(1);
opacity:0.75;
transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1);

&:hover{
  transform:scale(1.2);
  opacity:1;
}
`;

import { ResultsContext } from './context';

const Results = (props) => {

	const _c = useContext(ResultsContext);

	const [postObj, setPostObject] = useState(false);
	const [hasApiError, setApiError] = useState(false);
	const [onLoadMore, setOnLoadMore] = useState(false);
	const [allowScroll, setAllowScroll] = useState(false);

	const [activeType, setActiveSectionType] = useState();
	const [queryResults, setQueryResults] = useState(null);
  const [appLoading, setAppLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [recsQueried, setRecsQueried] = useState(false);
	const [notiRefs, setNotiRefs] = useState([]);

	const prevResults = useRef(null);
	const currResult = useRef(null);
	const currSortFilter = useRef(null);
	const _prev_query_id = useRef(null);
	const _questionnaire = useRef(null);

	const resultActiveSectionIdx = useRef(0);
	const resultsContainerRef = useRef(null);

	const recQuery = useRef({
	  questionnaire_id: _c.session.getSessionItem('questionnaire_id') || _c.context.param.questionnaire_id,
	  sort_by: 'preference',
	  limit: 6,
	});

	const scrollToElement = (section) => {
		const element = document.getElementById(section);

		let block = 'center';
		if (window.outerHeight < element.scrollHeight) block = 'start';
		var isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
		if (isSmoothScrollSupported) {
			element.scrollIntoView({
				behavior: 'smooth',
				block,
			});
		} else {
			element.scrollIntoView(false);
		}
	};

	const handleShowModal = () => {
		if (window[_c.context._appPrefix]) {

			_c.analytics.track('guided rec modal opened', {
				questionnaire_id: Number(_c.context.param?.questionnaire_id),
				page_url: window.location.href,
				page_pathname: window.location.pathname,
			});

			window[_c.context._appPrefix].renderComponent({
				type: 'questionnaire',
				questionnaire_id: _c.context.getQuestionnaireId() || _c.context.param?.questionnaire_id,
			});
		}
	};

	useEffect(() => {
		currResult.current = queryResults;
		prevResults.current = queryResults;

		if(queryResults?.notifications && queryResults?.notifications.length){
	        setNotiRefs((nItemRef) =>
	          queryResults.notifications.map((_, i) => nItemRef[i] || createRef())
	        );
	      }

	}, [queryResults]);

	const getQuestionnaireResults = (postObj) => _c.api.postQuestionnaire(postObj);
	const handleQuestinnaireResults = ({
	  questionnaireResults = null,
	  prevResults = null,
	  sort_by_filter = null,
	}) => {
    console.log('sort_by_filter', sort_by_filter)
	  if (
	    typeof questionnaireResults === 'object'
	    && Object.keys(questionnaireResults).length
	  ) {
	    let results = questionnaireResults;


	    const type_order = ['red', 'white', 'rose', 'sparkling', 'fortified'];
	    results.types = results.types.sort(
	      (a, b) => type_order.indexOf(a.nonLatinType)
	        - type_order.indexOf(b.nonLatinType)
	    ); // initial sort

	    if (
	      Object.prototype.toString.call(prevResults) === '[object Object]'
	      && prevResults.types.length
	    ) {
	      results = [...prevResults.types].reduce((currentArr, prevArr) => {
	        const currentTypeArr = currentArr.types.find(
	          (cTypeArr) => cTypeArr.nonLatinType === prevArr.nonLatinType
	        );
	        if (currentTypeArr) {
	          const newData = [...currentTypeArr.results];
	          const prevData = [...prevArr.results];
	          currentTypeArr.results = prevData.concat(newData);
	        }
	        if (!currentTypeArr) {
	          currentArr.types = [...currentArr.types, ...[prevArr]];
	        }
	        return currentArr;
	      }, results);

	      results.types = results.types.sort(
	        (a, b) => type_order.indexOf(a.nonLatinType)
	          - type_order.indexOf(b.nonLatinType)
	      ); // secondary sort when adding type update


	      if(sort_by_filter && sort_by_filter.startsWith('price_')){
	      	const _sortOrder = sort_by_filter.replace('price_', '');

	      	results.types = results.types.map((_t) => {
	      		_t.results = _t.results.sort((a, b) => {
					  const priceA = Number(a.lookups[0].price);
					  const priceB = Number(b.lookups[0].price);
					  return _sortOrder === 'asc' ? priceA - priceB : priceB - priceA;
						});

	      		return _t;
	      	});
	      }

	      setQueryResults(results);
	    }

	    if (!prevResults) {

	    	if(sort_by_filter && sort_by_filter.startsWith('price_')){
	      	const _sortOrder = sort_by_filter.replace('price_', '');

	      	results.types = results.types.map((_t) => {
	      		_t.results = _t.results.sort((a, b) => {
					  const priceA = Number(a.lookups[0].price);
					  const priceB = Number(b.lookups[0].price);
					  return _sortOrder === 'asc' ? priceA - priceB : priceB - priceA;
						});

	      		return _t;
	      	});
	      }

	      setQueryResults(results);
	    }

	    setRecsQueried(true);

	    if (results && results.types && results.types.length && !prevResults) setActiveSectionType(questionnaireResults.types[0].nonLatinType);
	  }
	};



	const handleQuery = async (postQuery = null) => {
	  return await getQuestionnaireResults({ ...postQuery, include_wines: true});
	};

	const doQuery = async ({sort_by_filter=null, loadingFn=setAppLoading, ...params}) => {

		loadingFn(true);

		let _query = {};

		if (_c.session.getSessionItem('question_responses') && _c.session.getSessionItem('filters')) {
        _query.question_responses = _c.session.getSessionItem('question_responses');
        _query.filters = _c.session.getSessionItem('filters');

        recQuery.current = {...recQuery.current, ..._query, ...params};

        handleQuery(recQuery.current).then((_qResults) => {

        	_c.session.updateSessionData('posted_query', true);

          const type_order = ['reds', 'whites', 'roses', 'sparkling', 'fortified'];
          _qResults.types = _qResults.types
            .sort((a, b) => type_order.indexOf(a.nonLatinType) - type_order.indexOf(b.nonLatinType))
        
          handleQuestinnaireResults({
            questionnaireResults: _qResults,
            sort_by_filter,
          });


					if(_qResults.types.length) setActiveSectionType(_qResults.types[0].nonLatinType);

					const _questionnaire_choices =  _qResults.questionnaire_choices.map((c) => (c.id)).flat();

					let _filters = {
						guidedrec_filter_pricemin: null,
						guidedrec_filter_pricemax: null,
					};

					if(_qResults.filters && _qResults.filters.length){
					_filters = _qResults.filters.reduce((obj, item) => {

					  if(item.key === 'price_min'){
					    obj.guidedrec_filter_pricemin = item.value;
					  }
					  if(item.key === 'price_max'){
					    obj.guidedrec_filter_pricemax = item.value;
					  }

					  return obj;
					}, _filters);
					}	

					if(_qResults?.notifications && _qResults?.notifications.length){ 
					  if(_qResults?.notifications.some((_n) => (_n.slug === 'waterfall_on_price_activated'))){ 
					    _c.analytics.track('guided rec price waterfall',{                  
					      questionnaire_id: _c.session.getSessionItem('questionnaire_id') || _c.context.param?.questionnaireId,
					      questionnaire_query_id: _qResults.id,
					      ..._filters,
					    });
					  }
					}

					_c.analytics.track('guided rec results',{            
						questionnaire_id: _c.session.getSessionItem('questionnaire_id') || _c.context.param?.questionnaireId,
						questionnaire_query_id: _qResults.id,
						guidedrec_number_of_choices: _questionnaire_choices.length,
						guidedrec_choices: _questionnaire.current.questions.reduce((arr, q) => {
						const _q_choices = q.choices.map((c) => (c.id)).flat();
						if(_questionnaire_choices.some((_qc) => _q_choices.includes(_qc))){
							const _filteredChoices = q.choices.filter((_c) => (_questionnaire_choices.includes(_c.id))).map(({id, text}) => ({id, text, slug:q.mixpanel_group_slug})).flat();
							arr = [...new Set([...arr, ..._filteredChoices])];
						}
						return arr;
						}, []),
						guidedrec_filters: _query.filters,
						guidedrec_results_total: _qResults.results_total,
						guidedrec_number_of_types: _qResults.types.length,
						guidedrec_types: _qResults.types.map((t) => (t.type.toLowerCase())).flat(),
						..._qResults.types.reduce((obj, _type, idx) => {
						obj[`product_type${(_qResults.types.length > 1) ? `_${idx}_${_type.type.toLowerCase()}` : ''}`] = _type.type.toLowerCase();
						obj[`product_type_${_type.type.toLowerCase()}_has_more`] = _type.hasNextPage;
						obj[`product_type_${_type.type.toLowerCase()}_results_total`] = _type.results.length;
						obj[`product_type_${_type.type.toLowerCase()}_product_names`] = [...new Set(_type.results.map((_result) => (_result.product.name)).flat())];
						obj[`product_type_${_type.type.toLowerCase()}_lookup_names`] = [...new Set(_type.results.map((_result) => {
						  let _pName = '';
						  const { product, lookups } = _result;
						  if(lookups.length) _pName = lookups[0].product_name;
						  return _pName;
						}).flat())];
						return obj;
						}, {})
					});

					loadingFn(false);

        })
        .catch((error) => {
          if(window.Rollbar) {
          	window.Rollbar.warning('Questionnaire Query Error', { 
	          	questionnaire_id: _c.session.getSessionItem('questionnaire_id') || _c.context.param?.questionnaireId,
							..._query, 
						})
          }

          _c.analytics.track('guided rec results not loaded', {            
						error: 'api',
						questionnaire_id: _c.session.getSessionItem('questionnaire_id') || _c.context.param?.questionnaireId,
						..._query,
					});

          loadingFn(false);
          setRecsQueried(true);
          setQueryResults(null);
        });
			} else {
				loadingFn(false);
				handleShowModal();
			}
	};


	useEffect(() => {

		const handleLoad = async () => {

			await _c.api.getQuestionnaire({
				id: Number(_c.context.param?.questionnaire_id),
			}).then((questionnaire) => {
				_questionnaire.current = questionnaire;
				_c.context.setQuestionnaire(questionnaire);
			}).catch((err) => {
				console.log('error loading questionnaire');
			});

      if(_questionnaire.current) {
      	doQuery({
	        limit: 6,
	        offset: 0,
	      });

	      currSortFilter.current = 'recommendation';
			}
		};

		handleLoad();
	}, []);

	const getMoreResults = (loadData) => {

		const { offset, type } = loadData;

		return new Promise(async (resolve, reject) => {

			const { max_results_per_type, max_results_per_page } = _questionnaire.current;
			let limit = null;

			if (!max_results_per_page 
				&& max_results_per_type 
				&& Number(offset) < max_results_per_type) {
				limit = 3;
				// limit = max_results_per_type - Number(offset);
			}

			if(max_results_per_page && limit > max_results_per_page){
				limit = max_results_per_page;
			}

			await getQuestionnaireResults({
				...recQuery.current,
				offset: Number(offset) + 1,
				...(limit !== null ? { limit } : {}),
				max_results_per_type,
				max_results_per_page,
				include_wines: true,
			}).then(
				(questionnaireResults) => {
					const filterType = questionnaireResults.types.filter(
						(qType) => qType.nonLatinType === type
					);

					questionnaireResults.types = filterType;

					if(filterType.length){
						handleQuestinnaireResults({
							questionnaireResults,
							prevResults: prevResults.current,
						});

						_c.analytics.track('guided rec show more results',{							
							questionnaire_id: _questionnaire.current.id,
							guidedrec_show_more_results_id: questionnaireResults.id,
							guidedrec_show_more_type: filterType[0].nonLatinType,
							guidedrec_show_more_results_total: filterType[0].results.length,
							show_more_type: type,
							...questionnaireResults.types.reduce((obj, _type, idx) => {
							obj[`product_type_${_type.type.toLowerCase()}`] = _type.type.toLowerCase();
							obj[`product_type_${_type.type.toLowerCase()}_has_more`] = _type.hasNextPage;
							obj[`product_type_${_type.type.toLowerCase()}_results_total`] = _type.results.length;
							obj[`product_type_${_type.type.toLowerCase()}_product_names`] = [...new Set(_type.results.map((_result) => (_result.product.name)).flat())];
							obj[`product_type_${_type.type.toLowerCase()}_lookup_names`] = [...new Set(_type.results.map((_result) => {
							  let _pName = '';
							  const { product, lookups } = _result;
							  if(lookups.length) _pName = lookups[0].product_name;
							  return _pName;
							}).flat())];
							return obj;
							}, {})
						});
					}
					resolve();
				},
				() => {
					reject();

					if(window.Rollbar) {
						window.Rollbar.warning('Questionnaire Query Error', { 
	          	show_more_type: type,
	            questionnaire_id: Number(_c.context.param.questionnaire_id),
	            ...postObj,
						});
					}

					_c.analytics.track('guided rec more results not loaded', {						
						error: 'api',
						show_more_type: type,
            questionnaire_id: Number(_c.context.param.questionnaire_id),
            ...postObj,
	        });
				}
			);
		});

	};

	const handleScrollChange = (entry, section) => {
		const results = Object.keys(queryResults).length
			? queryResults
			: currResult.current;
		if (results.types && results.types.length > 1 && !onLoadMore && allowScroll) {
			const currentSectionIdx = results.types.findIndex((type) => type.nonLatinType === section);

			const nextSectionIdx = (entry.scrolldirection.vertical)
			? Math.min(currentSectionIdx + 1, results.types.length - 1)
			: Math.max(0, currentSectionIdx - 1);

			resultActiveSectionIdx.current = nextSectionIdx;
			setActiveSectionType(results.types[nextSectionIdx].nonLatinType);
		}
	};

	const handleSectionClick = (e) => {
		let sectionLinkData;
		if (e.target.nodeName === 'SPAN' && e.target.parentNode.nodeName === 'LI') {
			sectionLinkData = { ...e.target.parentNode.dataset };
		}
		if (e.target.nodeName === 'LI') {
			sectionLinkData = { ...e.target.dataset };
		}

		if (activeType !== sectionLinkData.section) {
			resultActiveSectionIdx.current = Number(sectionLinkData.sectionIdx);
			setActiveSectionType(sectionLinkData.section);
			scrollToElement(sectionLinkData.section);
		} else {
			scrollToElement(sectionLinkData.section);
		}
	};

	const enableScrollChange = () => {
		if (!allowScroll) setAllowScroll(true);
	};	

	const hasResultError = () => hasApiError;

	const handleSortChange = (opt) => {
		if(currSortFilter.current === opt.id) return;
		currSortFilter.current = opt.id;

		doQuery({
			offset:0,
			limit: opt.id.startsWith('price_') ? 20 : 6,
			sort_by_filter: opt.id,
			loadingFn: setUpdateLoading,
		})
	}

	return (
		<>
			
			{appLoading && (
				<LoadingDots 
					className={ clsx('fade', appLoading && 'in', !appLoading && 'd-none') } 
					text={(_c.context.param?.customText && _c.context.param?.customText.resultsLoader) || undefined}
					color={ ((_c.context.param?.customStyling && _c.context.param?.customStyling.loadingColor) ? { backgroundColor: _c.context.param?.customStyling.loadingColor } : {}) } />
			)}

			<Conditional.Show when={(!appLoading)}>
				<div className={ clsx('irjs__results') }>

					<div className={ clsx(
							'irjs__results--heading',
							'irjs__results--heading-with-pbwr',
							_c.context.getCustomStyling('results.heading')
						) }>
							<h1>
								{(_c.context.param?.customText && _c.context.param?.customText.resultsHeading) || _c.lang.getSlugTranslation({slug:'result.resultsheading'})}
							</h1>
							{_c.context.param?.results_logo_placement === 'results' && (
								<div className="irjs__logo irjs__logo--top-right">
									{!_c.context.param?.preferabli_logo && <PoweredByLogo logoNumber={_c.context.param?.logo_option}/>}
									{_c.context.param?.preferabli_logo && <PreferabliLogo logoNumber={_c.context.param?.logo_option}/>}
								</div>
							)}
						</div>

						{(!appLoading && !hasResultError() && queryResults?.notifications && queryResults.notifications.length > 0) ? queryResults.notifications.map((_n, _idx) => {
	              const {slug, title, text} = _n;
	              return (
	                <div className={clsx('irjs__notify')} ref={ notiRefs[_idx] } key={`${_n.slug}_${_idx}`}>
	                  <h3>{`${_c.lang.getSlugTranslation({slug: `guidedrec.questionnaire.${slug}.title`})}`}</h3>
	                  <p>{`${_c.lang.getSlugTranslation({slug: `guidedrec.questionnaire.${slug}.text`})}`}</p>
	                  <NotiCloseButon role="button" onClick={() => removeNoti(notiRefs[_idx].current)}><MaterialIcon icon="close" /></NotiCloseButon>
	                </div>
	              );
	            }) : null
	          }

	          {_c.context.param?.resultsDisplayedAs === 'separated' && queryResults && queryResults.types && queryResults.types.length > 0 && !hasResultError() && !appLoading && (
	          	<div className={ clsx( 
	          		'irjs__results--outer', 
	          		_c.context.param?.layout === 'column' && 'irjs__results--column'
	          	) }>
	          		<Sidebar 
	          			activeSection={ activeType } 
	          			sections={ queryResults.types } 
	          			onClick={ handleSectionClick } 
	          			parentRef={ resultsContainerRef } 
	          		/>
	          		<div className="irjs__results--content" ref={ resultsContainerRef }>
	          		{_c.context.param?.enable_sort && (
	          			<SortByFilter 
	          			defaultValue={ _c.context.param?.default_sort || 'recommendation'} 
	          			onSelect={ handleSortChange }
	          			/>
	          		)}
	          		{updateLoading && (
	          			<LoadingDots 
	          				className={ clsx('fade', updateLoading && 'in', !updateLoading && 'd-none') } 
	          				text={(_c.context.param?.customText && _c.context.param?.customText.resultsLoader) || undefined}
	          				color={ ((_c.context.param?.customStyling && _c.context.param?.customStyling.loadingColor) ? { backgroundColor: _c.context.param?.customStyling.loadingColor } : {}) } />
	          		)}
	          		{!updateLoading && queryResults.types.map((grouping, idx) => (
	          			<ResultsSection
	          				key={ grouping.nonLatinType }
	          				id={ grouping.nonLatinType }
	          				loadMore={ getMoreResults }
	          				activeSection={ activeType }
	          				onScrollChange={ handleScrollChange }
	          				renderProductCards={ props.renderProductCards }
	          				onRenderComplete={ props.onRenderComplete }
	          				onComplete={ enableScrollChange }
	          				{ ...grouping }/>
	          		))}
	          		</div>
	          	</div>
	          )}
	          {queryResults && queryResults.types && !queryResults.types.length && !appLoading && !hasApiError && <NoResultsPlaceholder />}
	          {!queryResults && !appLoading && !hasApiError && (<DefaultPlaceholder />)}
	          {hasResultError() && !appLoading && (<ErrorPlaceholder />)}

				</div>
			</Conditional.Show>
		</>
	);
};

Results.propTypes = {
	onRenderComplete: PropTypes.func,
	renderProductCards: PropTypes.func,
};


export default React.memo(Results);
