import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import throttle from 'lodash/throttle';

import usePrevious from 'HOOKS/usePrevious';
import useMountedRef from 'HOOKS/useMountedRef';
import { checkValues, convertToPercentage, getKeyFromPos, getPositionFromValue, getPositions, getValueFromPos, setToNearestStep, } from './range/utils';
import RangeSlide from './range/slide';
import RangeTrack from './range/track';

const InputMinMaxSlider = (props) => {

  const {
    className,
    disabled=false,
    name=null,
    label=null,
    step=1,
    minValue=0,
    maxValue=10,
    buffer=5,
    showMinMaxLabels=false,
    labelCuttOff=20,
    allowLabelSwitch=false,
    onChange=() => {},
    formattedLabel=null,
  } = props;

  const mounted = useMountedRef();
  const rangeNode = useRef(null);
  const tNode = useRef(null);
  let isSlideDragging = false;
  const onChangeThrottle = useCallback(throttle(onChange, 1000), []);
  const [lastKeyMoved, setLastKey] = useState();
  const [labelPosition, setLabelPosition] = useState({
    min: false,
    max: false,
  });
  const [currentPositions, setPositions] = useState();
  const [percentages, setPercentages] = useState({});
  const curStep = useRef();

  const [rangeValues, setRangeValues] = useState(props.values);
  const prevValues = usePrevious(rangeValues);

  useEffect(() => { 
      // reset previous data     
      setLastKey(); 
      setPositions();

      setRangeValues(props.values);
      setPercentages({
        min: convertToPercentage((rangeValues && rangeValues.min) || minValue, props),
        max: convertToPercentage((rangeValues && rangeValues.max) || maxValue, props),
      });

      if (allowLabelSwitch && rangeValues && rangeValues.max) {
        const minValue = (rangeValues && rangeValues.min) || minValue;
        const maxValue = (rangeValues && rangeValues.max) || maxValue;

        setLabelPosition({
          max: Boolean(maxValue - minValue <= labelCuttOff),
        });
      }

  }, [props.values]);

   useEffect(() => {
    const updateProps = { ...props, step: curStep.current };
    if(prevValues && rangeValues && (prevValues.min !== rangeValues.min || prevValues.max !== rangeValues.max)){
      setPercentages({
        min: convertToPercentage(rangeValues.min || minValue, updateProps),
        max: convertToPercentage(rangeValues.max || maxValue, updateProps),
      });
      onChangeThrottle(rangeValues);
    }
  }, [rangeValues]);

   const updatePosition = (key, position) => {
    const { width } = tNode.current.getBoundingClientRect();
    const {
      minValue=0, maxValue=10, labelCuttOff=20, allowLabelSwitch=false,
    } = props;

    const updateProps = { ...props, step: curStep.current };

    let positions = currentPositions;
    if (!positions) {
      positions = getPositions({ ...rangeValues }, tNode.current.getBoundingClientRect(), updateProps);
    }
    
    positions[key] = position;

    const minRatio = Math.round((minValue + (maxValue - minValue) * (positions.min.x / width)) / curStep.current);
    const maxRatio = Math.round((minValue + (maxValue - minValue) * (positions.max.x / width)) / curStep.current);

    const newValues = {
      min: positions.min.x !== 0 && minRatio * curStep.current > minValue ? minRatio * curStep.current : minValue,
      max: maxRatio * curStep.current > maxValue ? maxValue : maxRatio * curStep.current,
    };

    if (curStep.current > 1) {
      if (newValues.min % curStep.current > 0 && newValues.min > minValue) {
        newValues.min = setToNearestStep(newValues.min, updateProps);
      }
      if (newValues.max % curStep.current > 0 && newValues.max > maxValue) {
        newValues.max = setToNearestStep(newValues.max, updateProps);
      }
    }

    if (positions.max.x - positions.min.x <= width * 0.2 && allowLabelSwitch) {
      if (key === 'max') {
        if (!labelPosition.min) {
          setLabelPosition({
            ...labelPosition,
            max: true,
          });
        }
      }
      if (key === 'min') {
        if (!labelPosition.max) {
          setLabelPosition({
            ...labelPosition,
            min: true,
          });
        }
      }
    } else {
      setLabelPosition({
        min: false,
        max: false,
      });
    }

    setPositions(positions);
    setRangeValues(newValues);
  };

  const interactionEnd = () => {
    if (isSlideDragging) isSlideDragging = false;
  };

  const setPosition = (posX, key) => {
    setLastKey(key);

    requestAnimationFrame(() => {
      updatePosition(key, {
        x: posX,
        y: 0,
      });
    });
  };

  const getStepFromValue = (value) => {
    const step = Object.entries(step).find((range) => {
      const [valMax, rangeStep] = range;
      if (Number(valMax) >= value) return rangeStep;
    });
    const [valMax, rangeStep] = step;
    return rangeStep;
  };

  const handleSlideDrag = (e, key) => {
    if (disabled) return;

    const { left, width } = tNode.current.getBoundingClientRect();
    const { clientX } = e.touches ? e.touches[0] : e;

    isSlideDragging = true;

    requestAnimationFrame(() => {

      let key_pos_x = Math.min(Math.max(clientX - left, 0), width);
      let currentValue = getValueFromPos({ x: key_pos_x }, props, tNode.current.getBoundingClientRect());

      const roundedValue = Math.round(currentValue);
      const step = (props.step && typeof props.step === 'object') ? getStepFromValue(roundedValue) : Number(props.step);
      curStep.current = step;

      if (step > 1 && currentValue % step > 0) currentValue = setToNearestStep(currentValue, { ...props, step });

      if (key === 'min') {
        const { max } = rangeValues;
        const max_pos = getPositionFromValue(max, props, tNode.current.getBoundingClientRect());

        if ((max === currentValue || (max - currentValue) <= step)) {

          e.stopPropagation();
          e.preventDefault();
          const minValue = max - step;
          const newPos = getPositionFromValue(minValue, props, tNode.current.getBoundingClientRect());
          key_pos_x = newPos.x;
          setPosition(key_pos_x, key);

        } else {
          setPosition(key_pos_x, key);
        }
      }

      if (key === 'max') {
        const { min } = rangeValues;
        const min_pos = getPositionFromValue(min, props, tNode.current.getBoundingClientRect());

        if (min === currentValue || ((currentValue - min) <= step)) {
          
          e.stopPropagation();
          e.preventDefault();
          const maxValue = min + step;
          const newPos = getPositionFromValue(maxValue, props, tNode.current.getBoundingClientRect());
          key_pos_x = newPos.x;
          setPosition(key_pos_x, key);

        } else {
          setPosition(key_pos_x, key);
        }
      }

    });
  };

  const handleMouseUp = (e) => {
    interactionEnd();
    rangeNode.current.ownerDocument.removeEventListener('mouseup', handleMouseUp);
  };
  const handleMouseDown = (e) => {
    rangeNode.current.ownerDocument.removeEventListener('mouseup', handleMouseUp);
    rangeNode.current.ownerDocument.addEventListener('mouseup', handleMouseUp);
  };

  const handleTrackMouseDown = (e, pos) => {
    if (disabled || isSlideDragging) return;

    const { min, max } = rangeValues;

    e.preventDefault();

    const value = getValueFromPos(pos, props, tNode.current.getBoundingClientRect());

    const roundedValue = Math.round(value);
    const step = typeof step === 'object' ? getStepFromValue(roundedValue) : Number(step);
    curStep.current = step;

    const stepValue = Math.round(value / step) * step;
    const dragKey = getKeyFromPos({ ...rangeValues }, pos, props, tNode.current.getBoundingClientRect());
    setLastKey(dragKey);

    if (stepValue < max || stepValue > min) {
      requestAnimationFrame(() => {
        updatePosition(dragKey, pos);
      });
    }
  };

  return (
    <div className={clsx('irjs__range', className) }>
      {label && <label>{label}</label>}

      <div className="irjs__range--input">
        {showMinMaxLabels && (
          <div className="irjs__range--values">
            <div className="irjs__range--values-min">{minValue && <label>{minValue || 0}</label>}</div>
            <div className="irjs__range--values-max">{maxValue && <label>{maxValue || 100}</label>}</div>
          </div>
        )}
        <div className="irjs__rangefield" aria-disabled={ disabled } onMouseDown={ handleMouseDown } ref={ rangeNode }>
          <div className="irjs__rangefield--inner">
            <RangeTrack percentages={ percentages } onTrackMouseDown={ handleTrackMouseDown } ref={ tNode }>
              {['min', 'max'].map((key) => {
                let { 
                  minValue=0, 
                  maxValue=10 
                } = props;

                const value = rangeValues[key];
                const percentage = percentages[key];

                if (key === 'min') {
                  maxValue = rangeValues.max;
                } else {
                  minValue = rangeValues.min;
                }

                return (
                  <RangeSlide
                    formattedLabel={ formattedLabel }
                    key={ key }
                    rangeKey={ key }
                    minValue={ minValue }
                    maxValue={ maxValue }
                    value={ value }
                    percentage={ percentage }
                    onSlideDrag={ handleSlideDrag }
                    invertedLabel={ labelPosition[key] }
                    ariaControls=""
                    ariaLabelledby=""
                  />
                );
              })}
            </RangeTrack>
            {['min', 'max'].map((key) => {
              const defaultValue = key === 'min' ? minValue : maxValue;
              const hiddenValue = (rangeValues && rangeValues[key]) || defaultValue;
              return <input type="hidden" value={ hiddenValue } key={ key } name={ `rangeslider_value_${key}` } />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

InputMinMaxSlider.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  formattedLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
  name: PropTypes.string,
  label: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.shape()]),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  buffer: PropTypes.number,
  showMinMaxLabels: PropTypes.bool,
  labelCuttOff: PropTypes.number,
  allowLabelSwitch: PropTypes.bool,
  className: PropTypes.string,
};

export default InputMinMaxSlider;
