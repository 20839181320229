import Api             from './Api';
import { getNonLatin } from 'HELPERS/text';

export default class Integrations extends Api {

	constructor(){
		super();
		this._opts = null;
		this._lang = null;
		this._timeout = 0;
	}

	setup = (params) => {
		this.client.defaults.baseURL = `${this.client.defaults.baseURL}/${params.integration_id}/`;
		this.client.defaults.headers.client_interface = `gr_${params.integration_id}`;
		this.client.defaults.headers.client_interface_version = `${__VERSION__}`;
		this._opts = params.opts;
		this._lang = params.lang;

		this._timeout = params.timeout;

		// setup rollbar errors
		this.client.interceptors.response.use(
		  response => response,
		  error => {
		  	if(window.Rollbar){
			    if (error.response) {
			       window.Rollbar.warning('HTTP Error', {
			        status: error.response.status,
			        data: error.response.data,
			        headers: error.response.headers,
			        url: error.config.url,
			        method: error.config.method
			      });
			    } else if (error.request) {
			      // No response received
			      window.Rollbar.warning('Request Error: No response received', {
			        request: error.request,
			        url: error.config.url,
			        method: error.config.method
			      });
			    } else {
			      // Request setup error
			      window.Rollbar.warning('Request Setup Error', { message: error.message });
			    }
			}
		    
		    return Promise.reject(error);
		  }
		);
	}

	getQuestionnaire = (params) => this.get('questionnaire', params).then((questionnaire) => {
		questionnaire.questions.forEach((question) => {
			question.choices.sort((a, b) => (a.number > b.number ? 1 : b.number > a.number ? -1 : 0));
		});
		questionnaire.questions = questionnaire.questions.sort((a, b) => (a.number > b.number ? 1 : b.number > a.number ? -1 : 0));

		const _order = Object.values(questionnaire.questions).reduce((obj, key, idx) => {
			obj[idx] = key.id;
			return obj;
		}, {});

		return { ...questionnaire, _order };
	});

	postQuestionnaire = (params) => {
		const { limit, include_wines, max_results_per_type, max_results_per_page, ...queryParams } = params;

		queryParams.limit = limit + 1;

		if (!max_results_per_page && queryParams.offset + limit > max_results_per_type) {
			queryParams.limit = max_results_per_type - queryParams.offset;
		}

		if(max_results_per_page && queryParams.limit > max_results_per_page){
			queryParams.limit = max_results_per_page;
		}

		queryParams.timeout = this._timeout !== null ? this._timeout : 0;

		return this.post(`query${include_wines ? '?include_wines=true' : null}`,  {
	      ...queryParams,
	    }).then((results) => {
			if (results && results.types.length) {
				results.types.forEach((resultType, idx, typesArr) => {
					const nonLatinType = getNonLatin(resultType.type);
					const hasNextPage = resultType.results.length > limit;
					if (resultType.results.length > limit) resultType.results = resultType.results.slice(0, limit);
					const result_total = resultType.results.length;
					typesArr[idx] = {
						...resultType,
						hasNextPage,
						nonLatinType,
						result_total,
					};
				});
			}

			const results_total = results.types.reduce((a, b) => a + b.result_total, 0);

			return { ...results, results_total };
		}).catch((err) => Promise.reject(err));
	};
}

