const genereateStyleObj = (cssString = '') => {
	if(String(cssString).length === 0) return {}; /// return empty object

	if(String(cssString).length){
		const cssToObject = cssString.split(';')
			.map(cur => cur.split(':'))
			.reduce((obj, val) => {
		    let [key, value] = val;

		    if(String(key).length) {
				var camelCased = key.replace(/-[a-z]/g, (g) => g[1].toUpperCase());
			    obj[camelCased] = value;
		    }
		    return obj;
			}, {});

		return Object.assign({}, cssToObject);
	}
}


export default genereateStyleObj;