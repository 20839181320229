import * as React        from 'react';
import PropTypes         from 'prop-types';

const CardBody = (props) => (
	 <div className="irjs__card--body">
		{ props.children }
  </div>
);

CardBody.propTypes = {
	children: PropTypes.node,
};

export default CardBody;
