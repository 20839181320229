import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import AppConfig from 'UTILS/AppConfig';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import Placeholder from './placeholder';

const ErrorPlaceholder = (props) => {

	let errorResultProps = {
		copy: (
			<>
				<h2>{LokaliseLanguage.getSlugTranslation({slug:'result.errorheading'})}</h2>
				<h4>{LokaliseLanguage.getSlugTranslation({slug:'result.errorsubheading'})}</h4>
			</>
		),
		instantRecButton: {
			text: LokaliseLanguage.getSlugTranslation({slug:'result.updatebtn'}),
		},
		type: 'error',
	};

	if (AppConfig.param?.error && Object.keys(AppConfig.param?.error).length) {
		const { text, textSize, ...paramProps } = AppConfig.param?.error;

		errorResultProps = {
			...errorResultProps,
			...paramProps,
		};

		if (text) {
			errorResultProps = {
				...errorResultProps,
				copy: React.isValidElement(parse(text)) ? (
					<>{parse(text)}</>
				) : (
					<span style={ { fontSize: textSize } }>{text}</span>
				),
			};
		}
	}


	return (
		<Placeholder {...errorResultProps} />
	);
};

export default React.memo(ErrorPlaceholder);
