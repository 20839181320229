import PropType from 'prop-types';
import React, { useEffect, useContext, useState } from 'react';


const Conditional = {};
function Show({ when, fallback = null, children }) {
    return when ? children : fallback;
}

export default Object.assign(Conditional,{
	Show
});