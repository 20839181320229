import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Emitter from 'UTILS/Emitter';
import usePrevious from 'HOOKS/usePrevious';

export const QuestionnaireContext = React.createContext({});
QuestionnaireContext.displayName = 'QuestionnaireContext';

export const QuestionnaireProvider = ({ children, parameters }) => {

	const [choicesWithWords, setChoicesWithWords] = useState({});
	const [questionnaire, setQuestionnaire] = useState();
	const [activeIndex, setActiveIndex] = useState(0);
	const [questions, setQuestions] = useState([]);

	const [currentQuestion, setCurrentQuestion] = useState();
	const [nsQuestionIds, setNSQuestionIds] = useState([]);

	const [localQuestionnaireData] = useState(parameters.session.getSessionItem('questionnaire_values'));
	const [startOverDisabled, setStartOverDisabled] = useState(true);

	const [query, setQuery] = useState({
        integration_id: parameters.context.param?.integration_id || 1,
        questionnaire_id: parameters.context.param?.questionnaire_id || 1,
    });

    const _val = useRef({});	
    const _pV = usePrevious(_val);

    const setQuestionnaireValues = (values) => {
    	_val.current = values;
    };

	const setChoicesFilters = async (questions) => new Promise((resolve, reject) => {

	  if (!localQuestionnaireData) {
	  	resolve(questions.reduce((obj, question) => {
		  	if (question.type === 'multiple_choice') obj[question.id] = [];
		  	if (question.type == 'price_range' || question.type === 'ns_slider') obj[question.id] = { min: question.default_range_min || 0, max: question.default_range_max || 500 };
		  	return obj;
		},{}));
	  }

	  if (questions.length) {

	      /// through in Promise.all() output [choices, filters] => resolve(true);

	      const _questionValues = questions.reduce((obj, question) => {

	          if (question.type === 'multiple_choice') {
	              obj[question.id] = [];

	              if (localQuestionnaireData &&
	                  Array.isArray(localQuestionnaireData[question.id]) &&
	                  localQuestionnaireData[question.id].length
	              ) {
	                  obj[question.id] = [...localQuestionnaireData[question.id]];
	              }
	          }
	          if (question.type == 'price_range' || question.type === 'ns_slider') {
	              obj[question.id] = { min: question.default_range_min || 0, max: question.default_range_max || 500 };

	              if (localQuestionnaireData &&
	                  localQuestionnaireData[question.id] &&
	                  typeof localQuestionnaireData[question.id] === 'object' &&
	                  Object.keys(localQuestionnaireData[question.id]).length) {
	                  obj[question.id] = { ...localQuestionnaireData[question.id] };
	              }

	          }
	          return obj;

	      }, {});

	      setQuestionnaireValues(_questionValues);
	      resolve(_questionValues);

	  }

	});

	const clearQuestionValues = (_question) => new Promise((resolve, reject) => {

		if(!_question) {
			 _val.current = questionnaire.questions.reduce((obj, _q) => {
			 	if(!_q.ignore_reset_choice_selection_on_change){
				 	if(_q.type === 'multiple_choice') obj[_q.id] = [];
				 	if(_q.type === 'price_range' || _q.type === 'ns_slider') obj[_q.id] = { min: _q.default_range_min, max: _q.default_range_max };
				 }
			 	return obj
			 }, {});
		}

		if(_question){
		    _val.current = questionnaire.questions.reduce((obj, _q) => {
		        if(_q.id !== _question.id && Number(_q.number) > Number(_question.number) && !_q.ignore_reset_choice_selection_on_change) {
		          if(_q.type === 'multiple_choice') obj[_q.id] = [];
		          if(_q.type === 'price_range') obj[_q.id] = { min: _q.default_range_min, max: _q.default_range_max };
		        } else {
		          obj[_q.id] = _pV.current[_q.id];
		        }
		        return obj
		      }, {..._pV.current});
		}
		
	    resolve();
	});

	useEffect(() => {

        const contextUpdate = () => {;

            let hasChoices = false;

            if (_val.current) {
                hasChoices = Boolean(
                    Object.values(_val.current)
                    .filter((_v) => (Array.isArray(_v) && _v.length))
                    .reduce((arr, key, idx) => {
                        if (key.length) {
                            arr = arr.concat(key);
                        }
                        return arr;
                    }, []).length > 0
                );

                if (activeIndex >= 0 && hasChoices) {
                    setStartOverDisabled(false);
                }
                if (!hasChoices) {
                    setStartOverDisabled(true);
                }
            }
        };

        Emitter.addListener('choicesUpdated', contextUpdate);
        Emitter.addListener('questionnaireCleared', contextUpdate);
        return () => {
            Emitter.removeListener('choicesUpdated', contextUpdate);
            Emitter.removeListener('questionnaireCleared', contextUpdate);
        }
    }, [_val.current]);

	useEffect(() => {
	    // on change of active question scroll to top of page.
	    window.scrollTo(0, 0);

	    if(questions.length){
	    	setCurrentQuestion(questions[activeIndex]);
	    }

	}, [activeIndex]);



	const context = {
		_val,
		setQuestionnaireValues,
		query,
		questions,
		questionnaire,
		activeIndex,
		startOverDisabled,
		nsQuestionIds,
		currentQuestion,
		setQuery,
		setQuestions,
		setQuestionnaire,
		setActiveIndex,
		setChoicesFilters,
		setNSQuestionIds,
		choicesWithWords,
		setChoicesWithWords,
		clearQuestionValues,
		...parameters
	};

	return <QuestionnaireContext.Provider value={ context }>{children}</QuestionnaireContext.Provider>;
};

QuestionnaireProvider.propTypes = {};
