import React    from 'react';
import ReactDOMClient from 'react-dom/client';

import { isValidHtml } from 'HELPERS/validator';
import genereateStyleObj from 'UTILS/generatestyleobject';

import GuidedRecQuiz    from 'COMPONENTS/questionnaire';
import GuidedRecResults from 'COMPONENTS/results';

import AppConfig from 'UTILS/AppConfig';
import LocalSession from 'UTILS/LocalSession';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import Emitter from 'UTILS/Emitter';
import IntegrationsApi from 'API/IntegrationsApi';
import PreferabliAnalytics from 'UTILS/Vendor/analytics';
// import Rollbar from 'UTILS/Vendor/Rollbar';


class GuidedRecApp {
	constructor(options = {}) {
		this.mounted = null;
		this._rootAppType = {
			questionnaire: false,
			results: false,
		};

		const defaults = {
			openNewTab: true,
			multipage: true,
			devMode: false,
			typesPlural: false,
			resultsDisplayedAs: 'separated',
			resultsPerPage: 6,
			priceRange: {
				min: 0,
				max: 500,
			},
			priceCurrency: 'USD',
			showDecimalPlaces: true,
			numDecimalPlaces: 2,
			customStyling: {
				compact: true,
			},
			sidemenuStickyTop: 50,
			sidemenuStatic: false,
			actionButton: null,
			preferabli_logo: false,
			logo_position: 'bottom-right',
			logo_option: 9,
			logo_inline_style: '',
			logo_class: '',
			results_logo_placement: 'results',
			show_slider_price_cents: false,
			selected_question_idx: 0,
			lang: 'en',
			opt_out_tracking: false,
			placeholder: {},
			default_sort: 'recommendation',
			enable_sort: false,
		};

		let opts = {...defaults};

		if(Object.keys(options).length){

			const { customStyling, ...opt } = options;

			if (customStyling && Object.keys(customStyling).length) {
				defaults.customStyling = Object.assign(defaults.customStyling, customStyling);
			}

			// replace wineCategories with categories for different sub categories
			if(Object.keys(options).includes('customText') && options.customText?.wineCategories){
				const _categories = {...options.customText.wineCategories};
				delete options.customText.wineCategories;
				opt.customText = {
					...opt.customText,
					categories: {
						..._categories
					},
				}
			}

			if(!Object.keys(options).includes('logo_option')){
				opt.logo_option = defaults.logo_option;
			}

			if(Object.keys(options).includes('poweredByPosition')){
				opt.logo_position = options.poweredByPosition;
				delete options.poweredByPosition;
			}

			if(Object.keys(options).includes('wrLogoPlacement')){
				opt.results_logo_placement = options.wrLogoPlacement;
				delete options.wrLogoPlacement;
			}

			if(Object.keys(options).includes('logo_class') && String(options.logo_class).length){
				opt.logoClass = options.logo_class;
				delete options.logo_class;
			} else {
				opt.logoClass = defaults.logo_class;
				delete options.logo_class;
			}

			if(Object.keys(options).includes('logo_inline_style') && String(options.logo_inline_style).length){
				opt.logoInlineStyle = genereateStyleObj(options.logo_inline_style);
				delete options.logo_inline_style;
			} else {
				opt.logoInlineStyle = genereateStyleObj(options.logo_inline_style);
				delete options.logo_inline_style;
			}

			if(Object.keys(options).includes('logo_before') && String(options.logo_before).length){
				if(isValidHtml(options.logo_before)){
					opt.logoBefore = options.logo_before;
					delete options.logo_before;
				} else {
					throw 'Option:logo_before, requires to be a valid html string.';
				}
			}

			if(Object.keys(options).includes('logo_after') && String(options.logo_after).length){
				if(isValidHtml(options.logo_after)){
					opt.logoAfter = options.logo_after;
					delete options.logo_after;
				} else {
					throw 'Option:logo_after, requires to be a valid html string.';
				}
			} 

			if(Object.keys(options).includes('modifyQuestionShow') && options.modifyQuestionShow){
				opt.selected_question_idx = Number(options.modifyQuestionShow) - 1;
				delete options.modifyQuestionShow;
			}

			if(Object.keys(options).includes('showPriceSliderCents')){
				opt.show_slider_price_cents = options.showPriceSliderCents;
				delete options.showPriceSliderCents;
			}

			if(Object.keys(options).includes('showQuestionNumbers')){
				delete options.showQuestionNumbers;
			}


			if(Object.keys(options).includes('noResults')){
				options.placeholder = Object.assign({noresults: options.noResults}, {...options.placeholder});
				delete options.noResults;
			}

			if(Object.keys(options).includes('error')){
				options.placeholder = Object.assign({error: options.error}, {...options.placeholder});
				delete options.error;
			}


			opts = { ...opts, ...opt };
		}

		this.opts = opts;


		// if(window.Rollbar) {
		// 	window.Rollbar.configure({enabled: true});
		// }

		this._session = new LocalSession();
		this._session.setSessionPrefix(`pdjs_${this.opts.integration_id}_`)
		this._session.getLocalCookies();
		

		this._lang = new LokaliseLanguage();

		this._api = new IntegrationsApi();
		this._api.setup({
			integration_id: this.opts.integration_id,
			opts: this.opts,
			lang: this._lang,
		});

		this._analytics = new PreferabliAnalytics();
		this._analytics.setup({
			product_id: this.opts.product_id,
			integration_id: this.opts.integration_id,
			opt_out_tracking: this.opts.opt_out_tracking
		});


		this._context = new AppConfig();
		this._context.setIntegrationId(this.opts.integration_id);
		this._context.setQuestionnaireId(this.opts.questionnaire_id);
		this._context.setLanguage(this.opts.lang);
		this._context.setParameters(this.opts);

		document.addEventListener(`appUnmout_questionnaire`, this.updateAppType);
		document.addEventListener(`appUnmout_results`, this.updateAppType);

	}

	updateAppType = (event) => {
		if(this._rootAppType[event.detail.type]) this._rootAppType[event.detail.type] = false;
	}

	createAttchElement = (attachElem, newElement) => {
		var elementDiv = document.createElement('div');
		elementDiv.id = newElement.replace('#', '');

		if (typeof attachElem === 'string' || !(attachElem instanceof Element)) {
			if (document.querySelector(attachElem).nodeName === 'BODY') {
				const bodyEl = document.querySelector(attachElem);
				const firstScript = Object.values(document.querySelector('body').childNodes).find(
					(htmlTag) => htmlTag.nodeName === 'SCRIPT'
				);

				document.querySelector(attachElem).insertBefore(elementDiv, firstScript);
			}
			if (document.querySelector(attachElem)) {
				document.querySelector(attachElem).appendChild(elementDiv);
			}
		}
		if (attachElem instanceof Element) {
			attachElem.appendChild(elementDiv);
			resolve();
		}
		return elementDiv;
	}

	renderComponent = async ({type, questionnaire_id = null, element = null, resultsRedirect = null, ...options}) => {

		await this._lang.load(this.opts.lang);  // load before rendering app

		let App = null;
		let _root;

		const { container, attachTo, ...props } = this.opts;

		const rootNode = (type === 'results' && element && element instanceof Element) ? element : !document.querySelector( '#irjs__modal_placeholder')
				? this.createAttchElement('body', 'irjs__modal_placeholder')
				: document.querySelector( '#irjs__modal_placeholder');


		App = type === 'questionnaire' ? GuidedRecQuiz : GuidedRecResults;

		if(this._rootAppType && this._rootAppType[type]) return;

		// if root already being used, and same app, cancel;

		_root = ReactDOMClient.createRoot(rootNode);

		let renderParams = { ...props, ...options, root: _root };
		if (questionnaire_id) {
			this.opts.questionnaire_id = questionnaire_id;
			renderParams = { ...renderParams, questionnaire_id };
		}
		if (resultsRedirect) {
			this.opts.resultsRedirect = resultsRedirect;
			renderParams = { ...renderParams, resultsRedirect };
		}

		if (type === 'results' && !options.results_logo_placement) {
			renderParams = { ...renderParams, results_logo_placement: 'results' };
		}

		const _props = {
			...renderParams, 
			context: this._context, 
			api: this._api, 
			analytics: this._analytics,
			lang: this._lang,
			session: this._session,
		};

		this._rootAppType[type] = true;
		_root.render(<App { ..._props } />);
	}

	clearPrevData = () => {
		LocalSession.clearSessionData();
		console.log('data cleared');
	}
}

export default GuidedRecApp;
