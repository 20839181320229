import clsx                           from 'clsx';

import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';

const MaterialIcon = ({ icon, className }) => {
  const [mIcon, setIcon] = useState(icon);

  useEffect(() => {
    setIcon(icon);
  }, [icon]);

  return <i className={ clsx('material-symbols-outlined', className) }>{mIcon}</i>;
};

MaterialIcon.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default MaterialIcon;