export const localePrice = (price, currency = 'USD', decimals = 2) => {
  let price_string = new Intl.NumberFormat(window.navigator.language || window.navigator.userLanguage, {
    currency,
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: decimals,
  }).format(Number(String(price).replace(/[^\d\w\s\.\,]/g, '')));
  price_string = price_string.replace(/\s/g, '');
  return price_string;
};

export const toCurrency = (amount, options = {}) => {
  const config = {
    currency: 'USD',
    locale: 'US',
    ...options,
  };
  return (amount || 0).toLocaleString(config.locale, {
    style: 'currency',
    currency: config.currency,
  });
};
