import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Placeholder from './placeholder';

import { ResultsContext } from './context';

const ErrorPlaceholder = (props) => {

	const _c = useContext(ResultsContext);

	let placeholderProps = {
		copy: (
			<>
				<h2>{_c.lang.getSlugTranslation({slug:'result.errorheading'})}</h2>
				<h4>{_c.lang.getSlugTranslation({slug:'result.errorsubheading'})}</h4>
			</>
		),
		instantRecButton: {
			text: _c.lang.getSlugTranslation({slug:'result.updatebtn'}),
		},
		type: 'error',
	};

	if (_c.context.param?.placeholder?.error && Object.keys(_c.context.param?.placeholder?.error).length) {
		const { text, textSize, ...paramProps } = _c.context.param?.placeholder?.error;

		placeholderProps = {
			...placeholderProps,
			...paramProps,
		};

		if (text) {
			placeholderProps = {
				...placeholderProps,
				copy: React.isValidElement(parse(text)) ? (
					<>{parse(text)}</>
				) : (
					<span style={ { fontSize: textSize } }>{text}</span>
				),
			};
		}
	}


	return (
		<Placeholder {...placeholderProps} />
	);
};

export default React.memo(ErrorPlaceholder);
