import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const Link = ({ children, ...props }) => {
	const { openNewTab, onClick, ...linkProps } = props;

	return (
		<a
			{ ...linkProps }
			onClick={ onClick }
			target={ openNewTab ? '_blank' : '_self' }
			rel="noreferrer"
		>
			{children}
		</a>
	);
};

Link.propTypes = {
	children: PropTypes.node,
	product: PropTypes.shape(),
};

export default Link;
