import Mixpanel from './Mixpanel';
import { underscore } from 'HELPERS/text';
import AppConfig from 'UTILS/AppConfig';

class PreferabliAnalytics{
  constructor(){
  }

  track(event, params){
      Mixpanel.track(`${underscore(event)}`, { ...params, integration_id: AppConfig.getIntegrationId() });
  }
  
}

const Analytics = new PreferabliAnalytics();
export default Analytics;