import React, { useEffect, useRef, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import platformDetect from 'HELPERS/platformDetect';
import AppConfig from 'UTILS/AppConfig';


const RangeSlide = ({
  maxValue=10,
  minValue=0,
  invertedLabel=false,
  ...props
}) => {
  const node = useRef(null);

  const { isMobile } = platformDetect;
  useEffect(
    () =>
    /// do nothing
      () => {
        // unmount mouse/touch
        if (node.current) {

          if(isMobile){
            document.body.classList.remove('overflow-hidden');
          }

          node.current.ownerDocument.removeEventListener('mousemove', handleMove);
          node.current.ownerDocument.removeEventListener('mouseup', handleMoveEnd);
          node.current.ownerDocument.removeEventListener('touchmove', handleMove);
          node.current.ownerDocument.removeEventListener('touchend', handleMoveEnd);
        }
      },
    []
  );

  const handleMove = (e) => {

    if(isMobile && !document.body.classList.contains('overflow-hidden')){
      document.body.classList.add('overflow-hidden');
    }
    props.onSlideDrag(e, props.rangeKey);
    // e.preventDefault();
    
  };

  const handleMoveEnd = (e) => {

    if(isMobile && document.body.classList.contains('overflow-hidden')){
      document.body.classList.remove('overflow-hidden');
    }

    node.current.ownerDocument.removeEventListener('mousemove', handleMove);
    node.current.ownerDocument.removeEventListener('mouseup', handleMoveEnd);
    node.current.ownerDocument.removeEventListener('touchmove', handleMove);
    node.current.ownerDocument.removeEventListener('touchend', handleMoveEnd);
  };

  const handleMouseDown = (e) => {
    node.current.ownerDocument.removeEventListener('mousemove', handleMove);
    node.current.ownerDocument.addEventListener('mousemove', handleMove, {passive: true});
    node.current.ownerDocument.removeEventListener('mouseup', handleMoveEnd);
    node.current.ownerDocument.addEventListener('mouseup', handleMoveEnd, {passive: true});
  };
  const handleTouchStart = (e) => {
    node.current.ownerDocument.removeEventListener('touchmove', handleMove);
    node.current.ownerDocument.addEventListener('touchmove', handleMove, {passive: true});
    node.current.ownerDocument.removeEventListener('touchend', handleMoveEnd);
    node.current.ownerDocument.addEventListener('touchend', handleMoveEnd, {passive: true});
  };
  const setSlideStyle = () => ({
    position: 'absolute',
    left: `${(props.percentage || 0) * 100}%`,
  });

  return (
    <div className="irjs__rangefield--slide" ref={ node } style={ setSlideStyle() }>
      <span className={ invertedLabel ? 'inverted' : null }>
        {typeof props.formattedLabel === 'function' ? props.formattedLabel(props.value) : props.value}
      </span>
      <div
        className={ clsx('irjs__rangefield--slide-drag', AppConfig.getCustomStyling('questionnaire.rangeDrag')) }
        draggable="false"
        onMouseDown={ handleMouseDown }
        onTouchStart={ handleTouchStart }
        role="slide"
        tabIndex="0"
        aria-labelledby={ props.ariaLabelledby }
        aria-controls={ props.ariaControls }
        aria-valuemax={ minValue }
        aria-valuemin={ maxValue }
        aria-valuenow={ props.value }
      />
    </div>
  );
};

RangeSlide.propTypes = {
  ariaLabelledby: PropTypes.string,
  ariaControls: PropTypes.string,
  formattedLabel: PropTypes.func,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  percentage: PropTypes.number,
  value: PropTypes.number,
  onSlideDrag: PropTypes.func,
  onSlideEndDrag: PropTypes.func,
  rangeKey: PropTypes.string,
  invertedLabel: PropTypes.bool,
};

export default RangeSlide;
