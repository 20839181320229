export const isHTML = (str) => !(str || '')
    .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/gi, '')
    .replace(/(<([^>]+)>)/gi, '')
    .trim();
    
export const isFullUrl = (string) => {
    /* eslint no-control-regex: "error" */
    const regex = /(?:(?:(?:[a-z]+:)?\/\/)|www.)(?:S+(?::S*)?@)?(?:localhost|(?:(?:[a-z\u00a1-\uffff0-9][-_]*)*[a-z\u00a1-\uffff0-9]+)(?:.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:.(?:[a-z\u00a1-\uffff]{2,})))(?::d{2,5})?(?:[/?#][^s"]*)?/;
    const pattern = new RegExp(regex, 'i'); // fragment locator
    return !!pattern.test(string);
};

export const isBase64 = value => /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/.test(value);

export const isWebview = () => {
    const _ua = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
    const _rules = [
      // if it says it's a webview, let's go with that
      'WebView',
      // iOS webview will be the same as safari but missing "Safari"
      '(iPhone|iPod|iPad)(?!.*Safari)',
      // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
      // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
      'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
      // old chrome android webview agent
      'Linux; U; Android'
    ];

    const _regex = new RegExp('(' + _rules.join('|') + ')', 'ig');
    return !!_ua.match(_regex);
}

export const isAndroidWebview = () => {
    const _ua = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
    const _rules = [
      // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
      // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
      'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
      // old chrome android webview agent
      'Linux; U; Android'
    ];

    const _regex = new RegExp('(' + _rules.join('|') + ')', 'ig');
    return !!_ua.match(_regex);
}

export const isiOSWebview = () => {
    const _ua = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
    const _rules = [
      /// iOS webview will be the same as safari but missing "Safari"
      '(iPhone|iPod|iPad)(?!.*Safari)',
    ];

    const _regex = new RegExp('(' + _rules.join('|') + ')', 'ig');
    return !!_ua.match(_regex);
}

export const isValidHtml = (str) => {
  const fragment = document.createRange().createContextualFragment(str);
  fragment.querySelectorAll('*').forEach(el => el.parentNode.removeChild(el));
  return !(fragment.textContent || '').trim();
}