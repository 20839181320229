import React, { forwardRef, useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import clsx from 'clsx';

import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import { QuestionnaireContext } from './context';
import CoreQuestion from './corequestion';

const Question = forwardRef(function Question(prop, ref) {

	const {activeIndex, ...irContext} = useContext(QuestionnaireContext);

	useEffect(() => {
		PreferabliAnalytics.track(`guided rec question viewed`, { 
			question_id: prop.id, 
			question_type: prop.type, 
			question_mixpanel_slug: prop.mixpanel_slug,
			questionnaire_id: irContext.questionnaire.id
		});
	}, []);

	

	return (
		<>
			<CoreQuestion { ...prop } />
		</>
	);

});

export default Question;
