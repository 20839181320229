import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import AppConfig from 'UTILS/AppConfig';

const Link = ({ children, ...props }) => {
	const { capture, ...linkProps } = props;
	const openNewTab = AppConfig.param.openNewTab || false;

	return (
		<a
			{ ...linkProps }
			onClick={ (e) => {
				PreferabliAnalytics.track('guidedrec product clickthru', { 
				...capture, 
				});
			} }
			target={ openNewTab ? '_blank' : '_self' }
			rel="noreferrer"
		>
			{children}
		</a>
	);
};

Link.propTypes = {
	children: PropTypes.node,
	product: PropTypes.shape(),
};

export default Link;
