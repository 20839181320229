const convertToPercentage = (value, props) => {
  const { minValue, maxValue } = props;
  const validValue = Math.min(Math.max(value, minValue), maxValue);
  const validDiff = maxValue - minValue;
  const valuePercent = (validValue - minValue) / validDiff;
  return valuePercent || 0;
};

const getValueFromPos = (position, props, clientRect) => {
  const { width } = clientRect;
  const { maxValue, minValue } = props;
  const sizePercent = position.x / width || 0;
  const validDiff = maxValue - minValue;
  return minValue + validDiff * sizePercent;
};

const getPositionFromValue = (value, props, clientRect) => {
  const { width } = clientRect;
  const percent = convertToPercentage(value, props);
  return {
    x: percent * width,
    y: 0,
  };
};

const getPositions = (values, clientRect, props) => ({
  min: getPositionFromValue(values.min, props, clientRect),
  max: getPositionFromValue(values.max, props, clientRect),
});

const getKeyFromPos = (values, position, props, clientRect) => {
  const positions = getPositions({ ...values }, clientRect, props);
  const disMin = Math.sqrt((positions.min.x - position.x) ** 2 + (positions.min.y - position.y) ** 2);
  const disMax = Math.sqrt((positions.max.x - position.x) ** 2 + (positions.max.y - position.y) ** 2);
  // return (disMin < disMax) ? 'min' : 'max';
  return 'max';
};

const setToNearestStep = (value, props) => {
  const { maxValue, step } = props;
  if (value >= maxValue) value = maxValue;
  if (value > 0 && value < maxValue) return Math.ceil((value + 1) / step) * step;
  return value;
};

const checkValues = (values, props) => {
  const {
    minValue,
    maxValue,
    step,
    value: { min, max },
  } = props;

  return (!values.min && !values.max) || (values.min && values.max && values.min >= minValue && values.max <= maxValue && values.min < values.max && values.max - values.min >= step);
};

export {
  convertToPercentage, getValueFromPos, getPositionFromValue, getPositions, getKeyFromPos, setToNearestStep, checkValues,
};
