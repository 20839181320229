import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styled from 'styled-components';

import { bottleFormat } from 'HELPERS/formatter';
import { localePrice } from 'HELPERS/price';
import { omit, pick } from 'HELPERS/object';

import { createUTMTag } from 'UTILS/BrowserUtils';

import PreferabliAnalytics from 'UTILS/Vendor/analytics';

import Link from 'GLOBAL/Link';
import CardImage from 'COMPONENTS/card/cardimage';
import CardBody from 'COMPONENTS/card/cardbody';
import CardActions from 'COMPONENTS/card/cardactions';

import { ResultsContext } from './context';

const CardDiv = styled.div`
	${(props) => (props.$isactive) ? `
		visibility: visible;
		opacity: 1;
		transition: opacity 400ms ease-out;
		flex:1;
		` : `
		position: absolute;
		visibility: hidden;
		opacity: 0;
		top:0;
	`}
`;

const Card = ({ lookup, isactive }) => {

	const _c = useContext(ResultsContext);

	const {
		variant_id,
		variant_year,
		product_name,
		price,
		price_currency,
		format_ml,
		landing_url,
		image_url,
		value,
		...lookupProps
	} = lookup;

	let capture = pick(lookup, ['id','variant_id','variant_year','product_name','price','format_ml','landing_url']);

	const handleOnClick = (e) => {
		_c.analytics.track('guided rec product clickthru', {
			...capture, 
			product_id: value,
			product_url_with_params: e.currentTarget.href || '',
        });
	};

	return (
		<CardDiv className="irjs__card--inner" $isactive={ isactive }>
			<CardImage
				product={ {
					image_url,
					name: product_name,
					url: createUTMTag({
	                        url: landing_url,
	                        source: 'preferabli',
	                        medium: 'productrec',
	                        campaign: 'guidedrec',
	                        content: product_name.replace(/\s+/g, '-').toLowerCase()
	                      }) ,
					product_id: value,
					variant_id,
				} }
				onClick={ handleOnClick } 
			/>
			<CardBody>
				<h3>
					{landing_url && landing_url.length && (
					<Link 
						href={ createUTMTag({
	                        url: landing_url,
	                        source: 'preferabli',
	                        medium: 'productrec',
	                        campaign: 'guidedrec',
	                        content: product_name.replace(/\s+/g, '-').toLowerCase()
	                      })  } 
						title={ product_name } 
						onClick={ handleOnClick } 
						>
						{product_name}
					</Link>
					)}
					{!landing_url && (
						<span>{product_name}</span>
					)}
				</h3>
			</CardBody>
			<CardActions>
				{(price && landing_url) && (
					<Link
						href={ createUTMTag({
	                        url: landing_url,
	                        source: 'preferabli',
	                        medium: 'productrec',
	                        campaign: 'guidedrec',
	                        content: product_name.replace(/\s+/g, '-').toLowerCase()
	                      }) }
						title={ product_name }
						className={ clsx('irjs__btn', _c.context.getCustomStyling(['results.productCardBtn', 'basicButton'])) }
						role="button"
						onClick={ handleOnClick } 
					>
						{localePrice(price, price_currency, _c.context.hasDecimalPlaces())}
					</Link>
				)}
				{(price && !landing_url) && (
					<span className={clsx('my-auto')}>{localePrice(price, price_currency, _c.context.hasDecimalPlaces())}</span>
				)}
			</CardActions>
		</CardDiv>
	);
};
Card.propTypes = {
	lookup: PropTypes.shape(),
	isActive: PropTypes.bool,
};


const ProductCard = ({ lookups, compact, hasMultipleLookups }) => {

	const _c = useContext(ResultsContext);

	const [currentCard, setCard] = useState(0);
	const formatIndicators = hasMultipleLookups ? (
		<>
			<div className="irjs__card--format-opts">
				{}
				<div className="irjs__card--format-opts-wrapper">
					{lookups.map((lookup, idx) => {
						const { format_ml } = lookup;

						return (
							<div
								className={ clsx(
									'irjs__card--format-selector',
									!format_ml && 'irjs__card--format-selector-pearl',
									currentCard === idx && 'irjs__card--format-selector-active'
								) }
								key={ lookup.id }
								onClick={ (e) => {
									if (idx === currentCard) return;
									setCard(idx);
								} }
							>
								{format_ml ? bottleFormat(format_ml) : ''}
							</div>
						);
					})}
				</div>
			</div>
		</>
	) : null;

	return (
		<div className={ clsx('irjs__card--wrapper', compact && 'irjs__card--compact') }>
			<div className={ clsx('irjs__card', 'irjs__card--product', _c.context.getCustomStyling('results.productCard')) } data-product-id={(lookups.length) ? lookups[0].value : ''}>
				{lookups.map((lookup, idx) => (
					<Card key={ lookup.id } lookup={ lookup } isactive={ Boolean(currentCard === idx) } />
				))}
				{hasMultipleLookups && <>{formatIndicators}</>}
			</div>
		</div>
	);
};

ProductCard.propTypes = {
	lookups: PropTypes.arrayOf(PropTypes.shape()),
	compact: PropTypes.bool,
	hasMultipleLookups: PropTypes.bool,
};


export default ProductCard;
