import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Placeholder from './placeholder';

import { ResultsContext } from './context';

const NoResultsPlaceholder = (props) => {

	const _c = useContext(ResultsContext);

	let placeholderProps = {
		heading: '',
		copySize: 'h2',
		copy: (
			<>
				{_c.lang.getSlugTranslation({slug:'result.noresultsheading'})}
				<br />
				{_c.lang.getSlugTranslation({slug:'result.noresultssubheading'})}
			</>
		),
		instantRecButton: {
			text: _c.lang.getSlugTranslation({slug:'result.updatebtn'}),
		},
		type: 'noresults',
	};

	if (_c.context.param?.placeholder?.noresults && Object.keys(_c.context.param?.placeholder?.noresults).length) {
		const { text, textSize, ...paramProps } = _c.context.param?.placeholder?.noresults;

		placeholderProps = {
			...placeholderProps,
			...paramProps,
		};

		if (text) {
			placeholderProps = {
				...placeholderProps,
				copy: React.isValidElement(parse(text)) ? (
					<>{parse(text)}</>
				) : (
					<span style={ { fontSize: textSize } }>{text}</span>
				),
			};
		}
	}



	return (
		<Placeholder {...placeholderProps} />
	);
};

export default React.memo(NoResultsPlaceholder);
